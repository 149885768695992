/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import moment from 'moment'
// import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Popover, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { GPSTrackerRoute, OrderTrackingDetailRoute } from 'constants/routes'
import Button from 'components/Button'
import Modal from 'components/Modal'
// import TextInput from 'components/TextInput'
import NoRecords from 'components/RecordNotFound'
import { ITasksData } from 'interfaces/views'
import AssignTasksModal from 'views/assignTaskModal'
import ItemDetails from 'views/adminDashboard/tasksTable/taskDescriptionTable'
import ArrowIcon from 'assets/svg/ArrowIcon'
import DownArrowIcon from 'assets/svg/DownArrowIcon'
// import SearchIcon from 'assets/svg/SearchIcon'
import OptionIcon from 'assets/svg/OptionIcon'
import PrevIcon from 'assets/svg/PrevIcon'
import NextIcon from 'assets/svg/NextIcon'
import {
  NumberWrapper,
  QuantityWrapper,
  TaskStatusImage,
  TaskStatusText,
  TaskStatusUnAssignedWrapper,
  TaskStatusWrapper,
} from 'styles/views/adminDashboard/tableDescription'
import { AssignedButtonContainer } from 'styles/views/inviteAgentScreen/agentDetailSection'
// import { InputWrapper } from 'styles/views/dashboard'
import {
  ButtonContainer,
  //  RightSection
} from 'styles/views/adminDashboard'
import { ContentWrapper, Title, StyledTableWrapper } from 'styles/components/Navbar'
import { useMediaQuery } from 'hooks/useMobile'
import CustomPagination from './taskDescriptionTable/customPagination'

const TasksData = ({
  // search,
  scroll,
  assignedData,
  totalAssignedCount,
  totalUnAssignedCount,
  unassignedData,
  pageSize,
  currentPage,
  currentAssignedPage,
  setPageSize,
  // searchedText,
  setCurrentPage,
  setCurrentAssignedPage,
  getUnassigned,
  getassigned,
}: ITasksData) => {
  const [activeIndex, setActiveIndex] = useState('Pending')
  const [taskModal, setTaskModal] = useState(false)
  const [activeTask, setActiveTask] = useState('')
  const [popoverOpen, setPopoverOpen] = useState<any[]>([])
  // const { control } = useForm()
  const router = useHistory()

  const handleActionClick = (index: number) => {
    setPopoverOpen((prevOpen) => {
      const updatedOpen = [...prevOpen]
      updatedOpen[index] = false
      return updatedOpen
    })
  }

  const handlePopoverOpenChange = (index: number, open: any) => {
    setPopoverOpen((prevOpen) => {
      const updatedOpen = [...prevOpen]
      updatedOpen[index] = open
      return updatedOpen
    })
  }

  const content = (id: string, item: any, index: number) => {
    return (
      <ContentWrapper onClick={() => handleActionClick(index)}>
        <Title
          onClick={() => {
            router.push(`${GPSTrackerRoute.path.replace(':id', id)}`)
          }}
        >
          View
        </Title>
        {item?.status === 'Pending' || item?.status === 'Searching-for-Agent' ? (
          <Title onClick={() => handleClick(item?.status, item?._id)}>Assign Driver</Title>
        ) : null}
      </ContentWrapper>
    )
  }

  const columns1: ColumnsType<any> = [
    {
      title: 'Order No.',
      dataIndex: 'linked_order',
      key: 'linked_order',
      width: 145,
      // fixed: 'left',
      render: (data: { order: { id: string } }) => {
        return <NumberWrapper>{data?.order?.id}</NumberWrapper>
        // return <NumberWrapper>{data.toUpperCase().substring(0, 8)}</NumberWrapper>
      },

      onFilter: (value: any, record) => {
        return String(record?.status).toLowerCase().includes(value.toLowerCase())
      },
    },
    {
      title: 'Order Type',
      dataIndex: 'fulfillments',
      key: 'fulfillments',
      width: 145,
      // fixed: 'left',
      render: (data: any) => {
        const type = data?.length ? data[data?.length - 1]?.type : ''
        return (
          <NumberWrapper>{type === 'Prepaid' ? 'Delivery' : type === 'Reverse QC' ? 'Return' : type}</NumberWrapper>
        )
      },
    },
    {
      title: 'Store Name',
      dataIndex: 'linked_order',
      key: 'linked_order',
      width: 145,
      // fixed: 'left',
      render: (data) => {
        return <NumberWrapper>{data?.provider?.descriptor?.name}</NumberWrapper>
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'linked_order',
      key: 'linked_order',
      width: 115,
      render: (data: { items: [] }) => {
        let qty = 0
        data.items.forEach((value: any) => {
          qty = qty + value?.quantity?.count || 1
        })
        return <QuantityWrapper>{qty}</QuantityWrapper>
      },
      onFilter: (value: any, record) => {
        return (
          String(record?.status).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.task_id).toLowerCase().includes(value.toLowerCase())
        )
      },
    },
    {
      title: 'Weight',
      dataIndex: 'linked_order',
      key: 'linked_order',
      width: 80,
      render: (data: { order: { weight: { unit: string; value: string } } }) => {
        return (
          <NumberWrapper>
            {data?.order?.weight?.value}{' '}
            {data?.order?.weight?.unit === 'kilogram' || data?.order?.weight?.unit === 'Kilogram'
              ? 'kg'
              : data?.order?.weight?.unit}
          </NumberWrapper>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 200,
      render: (data: string | JSX.Element) => {
        if (typeof data === 'string') {
          return (
            <TaskStatusWrapper status={data} activeIndex={activeIndex}>
              <TaskStatusImage status={data} activeIndex={activeIndex} />
              <TaskStatusText status={data} activeIndex={activeIndex}>
                {data}
              </TaskStatusText>
            </TaskStatusWrapper>
          )
        }
      },
    },
    {
      title: 'Ordered At',
      dataIndex: 'orderConfirmedAt',
      key: 'time',
      width: 140,

      render: (data) => {
        const inputDateTime = data
        const convertedDateTime = moment(Math.floor(inputDateTime)).fromNow()
        return <NumberWrapper>{convertedDateTime}</NumberWrapper>
      },
    },
    {
      title: '',
      dataIndex: '_id',
      key: 'x',
      width: 80,
      // fixed: 'right',
      render: (id, item, index) => {
        return item.status !== 'Pending' ? (
          <Popover
            placement="bottomLeft"
            content={content(id, item, index)}
            trigger="hover"
            open={popoverOpen[index]}
            onOpenChange={(open) => handlePopoverOpenChange(index, open)}
          >
            <OptionIcon />
          </Popover>
        ) : (
          <Popover placement="bottomLeft">
            <OptionIcon />
          </Popover>
        )
      },
    },
  ]

  const columns2: ColumnsType<any> = [
    {
      title: 'Order No.',
      dataIndex: 'linked_order',
      key: 'linked_order',
      width: 145,
      // fixed: 'left',
      render: (data: { order: { id: string } }) => {
        return <NumberWrapper>{data?.order?.id}</NumberWrapper>
      },
      onFilter: (value: any, record) => {
        return String(record?.status).toLowerCase().includes(value.toLowerCase())
      },
    },

    {
      title: 'Order Type',
      dataIndex: 'fulfillments',
      key: 'fulfillments',
      width: 145,
      // fixed: 'left',
      render: (data: any) => {
        const type = data?.length ? data[data?.length - 1]?.type : ''
        return (
          <NumberWrapper>{type === 'Prepaid' ? 'Delivery' : type === 'Reverse QC' ? 'Return' : type}</NumberWrapper>
        )
      },
    },
    {
      title: 'Store Name',
      dataIndex: 'linked_order',
      key: 'linked_order',
      width: 145,
      // fixed: 'left',
      render: (data) => {
        return <NumberWrapper>{data?.provider?.descriptor?.name}</NumberWrapper>
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'linked_order',
      key: 'linked_order',
      width: 115,
      render: (data: { items: [] }) => {
        let qty = 0
        data.items.forEach((value: any) => {
          qty = qty + value?.quantity?.count || 1
        })
        return <QuantityWrapper>{qty}</QuantityWrapper>
      },

      onFilter: (value: any, record) => {
        return (
          String(record?.status).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.task_id).toLowerCase().includes(value.toLowerCase())
        )
      },
    },
    {
      title: 'Weight',
      dataIndex: 'linked_order',
      key: 'linked_order',
      width: 80,

      render: (data: { order: { weight: { unit: string; value: string } } }) => {
        return (
          <NumberWrapper>
            {data?.order?.weight?.value}{' '}
            {data?.order?.weight?.unit === 'kilogram' || data?.order?.weight?.unit === 'Kilogram'
              ? 'kg'
              : data?.order?.weight?.unit}
          </NumberWrapper>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 200,
      render: (data: string | JSX.Element) => {
        if (typeof data === 'string') {
          return (
            <TaskStatusUnAssignedWrapper status={data} activeIndex={activeIndex}>
              <TaskStatusImage status={data} activeIndex={activeIndex} />
              <TaskStatusText status={data} activeIndex={activeIndex}>
                {data}
              </TaskStatusText>
            </TaskStatusUnAssignedWrapper>
          )
        }
      },
    },
    {
      title: 'Ordered At',
      dataIndex: 'orderConfirmedAt',
      key: 'time',
      width: 140,
      render: (data) => {
        const inputDateTime = data
        const convertedDateTime = moment(Math.floor(inputDateTime)).fromNow()
        return <NumberWrapper>{convertedDateTime}</NumberWrapper>
      },
    },

    {
      title: '',
      dataIndex: '_id',
      key: 'x',
      width: 80,
      // fixed: 'right',
      render: (item) => {
        return (
          <Popover
            placement="bottomLeft"
            content={
              <ContentWrapper>
                <Title onClick={() => router.push(`${OrderTrackingDetailRoute.path.replace(':id', item)}`)}>
                  Track
                </Title>
                <Title onClick={() => router.push(`${GPSTrackerRoute.path.replace(':id', item)}`)}>View</Title>
              </ContentWrapper>
            }
            trigger="hover"
          >
            <OptionIcon />
          </Popover>
        )
      },
    },
  ]

  const handleClick = (status: string, taskId: string) => {
    if (status === 'Pending' || status === 'Cancelled' || status === 'Searching-for-Agent') {
      setTaskModal(true)
      setActiveTask(taskId)
    }
  }

  useEffect(() => {
    if (taskModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [taskModal])
  const totalPages = Math.ceil((activeIndex === 'Pending' ? totalUnAssignedCount : totalAssignedCount) / pageSize)

  const itemRender = (current: any, type: string, originalElement: any) => {
    const isPrevDisabled = current === 0
    const isNextDisabled = current === totalPages

    const disabledStyle = {
      cursor: 'not-allowed',
      opacity: 0.5,
    }
    if (type === 'prev') {
      return (
        <button className="prev-button" disabled={isPrevDisabled} style={isPrevDisabled ? disabledStyle : {}}>
          <div>
            <PrevIcon /> Previous
          </div>
        </button>
      )
    }

    if (type === 'next') {
      return (
        <button className="next-button" disabled={isNextDisabled} style={isNextDisabled ? disabledStyle : {}}>
          <div>
            Next
            <NextIcon />
          </div>
        </button>
      )
    }

    return originalElement
  }

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - pageSize)
    }
  }

  const handleNext = () => {
    if (currentPage < (totalPages - 1) * pageSize) {
      setCurrentPage(currentPage + pageSize)
    }
  }

  const handleAssignPrev = () => {
    if (currentAssignedPage > 0) {
      setCurrentAssignedPage(currentAssignedPage - pageSize)
    }
  }

  const handleAssignNext = () => {
    if (currentAssignedPage < (totalPages - 1) * pageSize) {
      setCurrentAssignedPage(currentAssignedPage + pageSize)
    }
  }

  const isMobile = useMediaQuery('(max-width: 768px)')

  return (
    <>
      <AssignedButtonContainer>
        <ButtonContainer>
          <Button
            type="button"
            label="Unassigned"
            variant="outline"
            onClick={() => {
              setActiveIndex('Pending')
              getUnassigned()
              setCurrentPage(0)
              setCurrentAssignedPage(0)
            }}
            className={activeIndex === 'Pending' ? 'filled' : 'notfilled'}
          />
          <Button
            type="button"
            label="Assigned"
            variant="outline"
            className={activeIndex === 'Agent-assigned' ? 'filled' : 'notfilled'}
            onClick={() => {
              setActiveIndex('Agent-assigned')
              getassigned()
              setCurrentPage(0)
              setCurrentAssignedPage(0)
            }}
          />
        </ButtonContainer>
      </AssignedButtonContainer>
      <>
        {activeIndex === 'Pending' ? (
          !isMobile ? (
            <StyledTableWrapper id="styled-table-wrapper">
              <Table
                scroll={{ y: scroll === undefined ? 320 : scroll }}
                columns={columns1}
                locale={{ emptyText: <NoRecords /> }}
                dataSource={unassignedData}
                pagination={
                  totalUnAssignedCount > 5 && {
                    current: Math.ceil(currentPage / pageSize) + 1,
                    total: totalUnAssignedCount,
                    onChange: (currentPage, pageSize) => {
                      const newSkipValue = (currentPage - 1) * pageSize
                      setCurrentPage(newSkipValue)
                      setPageSize(pageSize)
                    },
                    itemRender: itemRender,
                  }
                }
                rowKey="task_id"
              />
            </StyledTableWrapper>
          ) : (
            <StyledTableWrapper id="styled-table-wrapper">
              <Table
                scroll={{ y: 590 }}
                columns={columns1}
                locale={{ emptyText: <NoRecords /> }}
                dataSource={unassignedData}
                pagination={false}
                rowKey="task_id"
              />
              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPrev={handlePrev}
                onNext={handleNext}
                pageSize={pageSize}
              />
            </StyledTableWrapper>
          )
        ) : !isMobile ? (
          <StyledTableWrapper id="styled-table-wrapper">
            <Table
              scroll={{ y: scroll === undefined ? 200 : scroll }}
              columns={columns2}
              locale={{ emptyText: <NoRecords /> }}
              dataSource={assignedData}
              expandable={{
                expandedRowRender: (record) => <ItemDetails record={record} />,
                expandIcon: ({ expanded, onExpand, record }) => (
                  <span onClick={(e) => onExpand(record, e as React.MouseEvent<HTMLSpanElement, MouseEvent>)}>
                    {expanded ? <DownArrowIcon /> : <ArrowIcon />}
                  </span>
                ),
              }}
              pagination={
                totalAssignedCount > 5 && {
                  // pageSizeOptions: ['5', '10', '20'],
                  // showSizeChanger: true,
                  // locale: { items_per_page: 'Records Per Page' },
                  current: Math.ceil(currentAssignedPage / pageSize) + 1,
                  // pageSize: pageSize,
                  total: totalAssignedCount,
                  onChange: (currentAssignedPage, pageSize) => {
                    const newSkipValue = (currentAssignedPage - 1) * pageSize
                    setCurrentAssignedPage(newSkipValue)
                    setPageSize(pageSize)
                  },
                  itemRender: itemRender,
                }
              }
              rowKey="task_id"
            />
          </StyledTableWrapper>
        ) : (
          <StyledTableWrapper id="styled-table-wrapper">
            <Table
              scroll={{ y: 590 }}
              columns={columns2}
              locale={{ emptyText: <NoRecords /> }}
              dataSource={assignedData}
              expandable={{
                expandedRowRender: (record) => <ItemDetails record={record} />,
                expandIcon: ({ expanded, onExpand, record }) => (
                  <span onClick={(e) => onExpand(record, e as React.MouseEvent<HTMLSpanElement, MouseEvent>)}>
                    {expanded ? <DownArrowIcon /> : <ArrowIcon />}
                  </span>
                ),
              }}
              pagination={false}
              rowKey="task_id"
            />
            <CustomPagination
              currentPage={currentAssignedPage}
              totalPages={totalPages}
              onPrev={handleAssignPrev}
              onNext={handleAssignNext}
              pageSize={pageSize}
            />
          </StyledTableWrapper>
        )}
        <Modal isOpen={taskModal}>
          <AssignTasksModal
            showModal={(value: boolean) => setTaskModal(value)}
            activeTask={activeTask}
            refetchTask={getUnassigned}
          />
        </Modal> 
      </>
    </>
  )
}

export default TasksData
