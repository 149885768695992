import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
// import { Collapse } from 'antd'
import moment from 'moment'
import APIS from 'constants/api'
import useGet from 'hooks/useGet'
import Modal from 'components/Modal'
import Button from 'components/Button'
import CancelOrderModal from 'views/driverFlowHome/cancelOrderModal'
// import OrderStepper from 'views/driverFlowHome/orderStepper'
import DriverUpdateStatusModal from 'views/driverFlowHome/driverUpdateStatusModal'
import { IParamId } from 'interfaces/pages'
import { IButtonData } from 'interfaces/views'
import DownloadInvoiceIcon from 'assets/svg/DownloadInvoiceIcon'
// import { AppContext } from 'context/payloadContext'
import { maskMobileNumber } from 'utils/maskMobileNumber'
import {
  OrderWrapper,
  NameWrapper,
  TaskDetailsWrap,
  TaskID,
  TaskAssigned,
  Status,
  TrackOrderWrapper,
  // HistoryScreenWrapper,
  OrderDetailWrapper,
  OrderText,
  OrderDescriptionWrapper,
  OrderDescription,
  QuantityHeading,
  WeigthHeading,
  QuantityWeightWrapper,
  ItemsName,
  QuantityName,
  OrderDescriptiontext,
  QuantityWeight,
  ResultWrapper,
  TotalOrder,
  DownloadButtonSection,
  ButtonWrap,
  CallWrapper,
  DriverStatusWrapper,
  // PaymentWrapper,
} from 'styles/views/driverFlowHome'
import PrevIcon from 'assets/images/arrow-left.png'
import { ImageWrapper } from 'styles/views/dashboard'
import CallIcon from 'assets/svg/CallIcon'

// const { Panel } = Collapse

const TrackOrder = ({ buttonStatus }: IButtonData) => {
  const [size, setSize] = useState(0)

  const [statusModal, setStatusModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [orderDetail, setOrderDetail] = useState('')
  // const [taskData, setTaskData] = useState<any>([])
  // const router = useHistory()
  const { id }: IParamId = useParams()
  // const { userInfo } = useContext(AppContext)

  const { refetch: getTask, data: taskDetails } = useGet('get-task', `${APIS.ALL_TASK}/${id}/agent`)

  useEffect(() => {
    getTask()
  }, [])

  const handleClick = (e: any) => {
    setOrderDetail(e)
    if (e === 'Cancelled') {
      setStatusModal(!statusModal)
      setCancelModal(!cancelModal)
    }
  }

  const openStatus = () => {
    if (['Order-delivered', 'Cancelled', 'RTO-Delivered', 'RTO-Disposed'].includes(taskDetails?.data?.task?.status))
      setStatusModal(false)
    else {
      setStatusModal(true)
    }
  }

  // let total = 0
  let totalAmount = 0

  const query = () => {
    setSize(window?.innerWidth)
  }

  useEffect(() => {
    setSize(window.innerWidth)
    window.addEventListener('resize', query)
    return () => {
      window.removeEventListener('resize', query)
    }
  }, [size])

  // useEffect(() => {
  //   const taskData = taskDetails?.data?.taskStatus?.filter((obj: any) => obj?.agentId === userInfo?.agentId)
  //   setTaskData(taskData)
  // }, [userInfo])
  return (
    <>
      <TrackOrderWrapper>
        <ImageWrapper style={{ maxWidth: 'unset' }}>
          <img src={PrevIcon} alt="agent-image" onClick={() => history.back()} />
        </ImageWrapper>
        <OrderWrapper className="order-wrapper">
          <NameWrapper className="name-wrapper">
            <TaskDetailsWrap>
              <TaskID>Order ID</TaskID>
              <TaskID>
                <span>{taskDetails?.data?.task?.linked_order?.order?.id}</span>
              </TaskID>
              {/* <PaymentWrapper>Payment : {taskDetails?.data?.task?.payment?.type} </PaymentWrapper> */}
            </TaskDetailsWrap>
            <CallWrapper>
              <TaskDetailsWrap>
                <TaskID>Mobile number</TaskID>
                {maskMobileNumber(taskDetails?.data?.task?.fulfillments[0]?.end?.contact?.phone)}
              </TaskDetailsWrap>
              {/* <a href={`tel:+91${taskDetails?.data?.task?.fulfillments[0]?.end?.contact?.phone}`}>📞 Call </a> */}
            </CallWrapper>
          </NameWrapper>
          <DriverStatusWrapper>
            <Status status={taskDetails?.data?.task?.status}>{taskDetails?.data?.task?.status}</Status>
            <TaskAssigned>{moment(`${taskDetails?.data?.task?.createdAt}`).startOf('hour').fromNow()}</TaskAssigned>
            <CallWrapper>
              <a href={`tel:+91${taskDetails?.data?.task?.fulfillments[0]?.end?.contact?.phone}`}>
                <CallIcon />
                 Call </a>
            </CallWrapper>
          </DriverStatusWrapper>
          {/* {userInfo?.agentId && (
            <Status status={taskData[taskData?.length - 1]?.status}>{taskData[taskData?.length - 1]?.status}</Status>
          )} */}
        </OrderWrapper>
        {/* {userInfo?.agentId && <OrderStepper taskData={taskDetails?.data} agentId={userInfo?.agentId} />} */}
        <OrderWrapper className="order-wrapper">
          <OrderDescriptionWrapper>
            <OrderDetailWrapper>
              <OrderText className="order-text">Order Details</OrderText>
            </OrderDetailWrapper>
            <OrderDescription>
              {/* <OrderDescriptiontext>Name </OrderDescriptiontext>
              <QuantityHeading>Qty</QuantityHeading>
              <WeigthHeading>Weight</WeigthHeading> */}
            </OrderDescription>
            {taskDetails?.data?.task?.linked_order?.items.map((item: any, index: React.Key | null | undefined) => {
              // total += parseFloat(item?.quantity?.measure?.value)
              totalAmount += parseFloat(item?.price?.value)
              return (
                <QuantityWeightWrapper key={index}>
                  <div className="name-details">
                    <div className="name-column">
                      <OrderDescriptiontext className="description-text">Item Name</OrderDescriptiontext>
                      <ItemsName>{item?.descriptor?.name}</ItemsName>
                    </div>
                  </div>
                  <div className="quantity-weight">
                    <div className="quantity-column">
                      <QuantityHeading className="description-text">Qty</QuantityHeading>
                      <QuantityName>{item?.quantity?.measure?.value}</QuantityName>
                    </div>
                    <div className="weight-column">
                      <WeigthHeading className="description-text">Weight</WeigthHeading>
                      <QuantityWeight>
                        {item?.quantity?.measure?.value} {item?.quantity?.measure?.unit}
                      </QuantityWeight>
                    </div>
                  </div>
                </QuantityWeightWrapper>
              )
            })}
            {/* <ResultWrapper>
                  <OrderText>Total Qty</OrderText>
                  <TotalOrder>{total} kilogram</TotalOrder>
                </ResultWrapper> */}
            <ResultWrapper>
              <OrderText className="description-text">Total Price</OrderText>
              <TotalOrder className="total-order">₹ {totalAmount}</TotalOrder>
            </ResultWrapper>
          </OrderDescriptionWrapper>
        </OrderWrapper>

        {buttonStatus ? (
          <ButtonWrap>
            {/* <Button label="Go Back" variant="contained" className="cancel" onClick={() => router.push('/home')} /> */}
            <Button
              label="Update Status"
              variant={
                ['Order-delivered', 'Cancelled', 'RTO-Delivered', 'RTO-Disposed', 'Completed'].includes(
                  taskDetails?.data?.task?.status,
                )
                  ? 'disabled'
                  : 'contained'
              }
              type="submit"
              onClick={openStatus}
            />
          </ButtonWrap>
        ) : null}

        {false && (
          <DownloadButtonSection>
            <Button label="Download invoice" variant="outline">
              <DownloadInvoiceIcon />
            </Button>
          </DownloadButtonSection>
        )}
      </TrackOrderWrapper>
      <Modal isOpen={statusModal}>
        <DriverUpdateStatusModal
          showModal={(value: boolean) => setStatusModal(value)}
          handleClick={handleClick}
          orderDetail={orderDetail}
          task={taskDetails?.data?.task}
          taskStatus={taskDetails?.data?.taskStatus}
          getTask={() => getTask()}
        />
      </Modal>
      <Modal isOpen={cancelModal}>
        <CancelOrderModal
          showModal={(value: boolean) => setCancelModal(value)}
          task={taskDetails?.data?.task}
          getTask={() => getTask()}
        />
      </Modal>
    </>
  )
}

export default TrackOrder
