import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_33_2210)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.25C12.4142 7.25 12.75 7.58579 12.75 8V11.6893L14.5303 13.4697C14.8232 13.7626 14.8232 14.2374 14.5303 14.5303C14.2374 14.8232 13.7626 14.8232 13.4697 14.5303L11.4697 12.5303C11.329 12.3897 11.25 12.1989 11.25 12V8C11.25 7.58579 11.5858 7.25 12 7.25Z"
        fill="#181818"
        fillOpacity="0.4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.428 5.69619C15.9007 4.36338 13.9362 3.6395 11.9093 3.66265C9.88239 3.6858 7.93494 4.45436 6.43851 5.82171C4.94208 7.18905 4.00142 9.05946 3.796 11.0761C3.75403 11.4882 3.38594 11.7882 2.97386 11.7462C2.56178 11.7042 2.26175 11.3361 2.30372 10.9241C2.54649 8.54079 3.65818 6.33031 5.42668 4.71436C7.19519 3.09841 9.49673 2.19011 11.8922 2.16275C14.2876 2.13539 16.6093 2.99088 18.4143 4.56602C20.2192 6.14115 21.3811 8.32567 21.6782 10.7028C21.9754 13.0799 21.387 15.4832 20.0253 17.4541C18.6636 19.4251 16.6239 20.8258 14.2954 21.3889C11.967 21.952 9.51267 21.6382 7.4008 20.5073C5.92645 19.7178 4.69105 18.5686 3.79986 17.1811V20.0001C3.79986 20.4143 3.46408 20.7501 3.04986 20.7501C2.63565 20.7501 2.29986 20.4143 2.29986 20.0001V15.0001C2.29986 14.5859 2.63565 14.2501 3.04986 14.2501H3.53236C3.54401 14.2498 3.55564 14.2498 3.56724 14.2501H8.04986C8.46408 14.2501 8.79986 14.5859 8.79986 15.0001C8.79986 15.4143 8.46408 15.7501 8.04986 15.7501H4.70059C5.46391 17.2026 6.64773 18.4025 8.10891 19.185C9.89588 20.1419 11.9726 20.4074 13.9428 19.9309C15.9131 19.4544 17.639 18.2693 18.7912 16.6015C19.9434 14.9338 20.4412 12.9002 20.1898 10.8888C19.9384 8.87743 18.9553 7.02899 17.428 5.69619Z"
        fill="#181818"
        fillOpacity="0.4"
      />
    </g>
    <defs>
      <clipPath id="clip0_33_2210">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
