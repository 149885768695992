import { SVGProps } from 'react'

const HubsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity={0.4} clipPath="url(#clip0_66_3400)">
      <path d="M11 29H29" stroke="" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M27 29V25" stroke="" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M27 25C27.5304 25 28.0391 24.7893 28.4142 24.4142C28.7893 24.0391 29 23.5304 29 23V21C29 20.4696 28.7893 19.9609 28.4142 19.5858C28.0391 19.2107 27.5304 19 27 19C26.4696 19 25.9609 19.2107 25.5858 19.5858C25.2107 19.9609 25 20.4696 25 21V23C25 23.5304 25.2107 24.0391 25.5858 24.4142C25.9609 24.7893 26.4696 25 27 25Z"
        stroke=""
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 29V15C22 14.2044 21.6839 13.4413 21.1213 12.8787C20.5587 12.3161 19.7956 12 19 12H15C14.2044 12 13.4413 12.3161 12.8787 12.8787C12.3161 13.4413 12 14.2044 12 15V29"
        stroke=""
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M17 25V29" stroke="" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16 21H18" stroke="" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16 17H18" stroke="" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_66_3400">
        <rect width={24} height={24} fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
)

export default HubsIcon
