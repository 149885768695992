import styled from 'styled-components'
import { theme } from 'styles/theme'

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 60px;
  min-height: calc(100% - 106px);
  @media (max-width: 1224px) {
    padding: 20px;
  }

  @media (max-width: 768px){
    padding-top: 0px;
  }
`

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
`

export const Container = styled.div`
  display: flex;
  gap: 15px;
`
export const MainHeading = styled.div`
  font-family: 'Inter';
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: ${theme.PRIMARYBLACKCOLOR};
  display: flex;
  gap: 10px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`

export const ActivityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 16px;

  @media (max-width: 1300px) {
    padding: 20px 14px;
  }
  @media (max-width: 1024px) {
    padding: 20px 10px;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    background: ${theme.SEMIBLUECOLOR};
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    padding: 14px 12px;

    color: ${theme.PRIMARYBLACKCOLOR};
    ::before {
      background-color: none !important;
    }
  }
  .ant-table-cell {
    svg {
      cursor: pointer;
    }
  }
  .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-start-radius: 0;
  }
  .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
    border-start-end-radius: 0;
  }
  .ant-table-wrapper .ant-table-thead > tr > th::before {
    background: none !important;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${theme.GREYCOLOR};
    padding: 14px;
    span {
      cursor: pointer;
    }
  }
  .ant-table-wrapper .ant-table-pagination.ant-pagination {
    margin: 16px 0 0;
  }
  .ant-table-wrapper .ant-table-thead > tr > td {
    background: ${theme.SEMIBLUECOLOR};
  }
  .ant-table-wrapper .ant-table .ant-table-header {
    border-radius: 0px;
  }
`
export const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 15px;
  background-color: ${theme.WHITE};
  border: 1px solid ${theme.GREYBORDER};
  border-radius: 6px;
`

export const SearchWrapper = styled.form`
  display: flex;
  padding: 30px 25px;
  + div {
    margin-top: 0px;
  }

  &.search-wrapper {
    @media (max-width: 768px) {
        padding: 20px 20px 10px 20px;
      > div {
        > div {
          width: unset !important;
        }
      }
    }
  }
`

export const ButtonWrapper = styled.div`
  margin-left: 10px;
  display: flex;
  gap: 8px;
  button {
    flex-direction: row-reverse;
    width: 105px;
    height: 40px;
    gap: 10px;
    font-weight: 500;
    font-size: 14px;
    :hover {
      background-color: ${theme.SECONDARYBLUECOLOR};
      color: ${theme.WHITE};
      svg {
        path {
          fill: ${theme.WHITE};
        }
      }
    }
  }
  .active-button {
    svg {
      path {
        fill: ${theme.WHITE};
      }
    }
  }
  .color {
    background-color: ${theme.SECONDARYBLUECOLOR};
  }

  &.search {
    button {
      @media (max-width: 768px) {
        width: 70px;
      }
    }
  }
`

export const InputWrapper = styled.div`
  .ant-input-affix-wrapper {
    height: 40px;
    border-radius: 10px;
  }
  .ant-input-affix-wrapper > input.ant-input {
    height: 28px;
  }
  input {
    ::placeholder {
      color: ${theme.PLACEHOLDER_COLOR};
    }
  }
`

export const RecordsWrapper = styled.div`
  display: flex;
  // height: 475px;
  padding: 10px 30px;
  gap: 15px;
  @media (max-width: 768px) {
    flex-flow: wrap-reverse;
    padding: 0 20px 20px 20px;
  }
  @media (min-width: 768px) {
    height: calc(100vh - 400px);
  }
`

export const LeftRecordsWrapper = styled.div`
  width: 30%;
  border: 1px solid #99999940;
  border-radius: 6px;
  @media (max-width: 768px) {
    width: 100%;
    min-height: 60vw;
  }
`

export const RightRecordsWrapper = styled.div`
  width: 64.4%;
  margin: 0px 10px;
  overflow: auto;
  border-radius: 5px;
  background-color: #263238;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    min-height: 70vw;
  }
`

export const CodeMirrorWrapper = styled.div`
  .CodeMirror {
    height: 475px;
  }
`

export const RecordLabel = styled.div`
  padding: 15px 10px;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.TITLECOLOR};
  border-bottom: 1px solid ${theme.GREYBORDER};
`

export const MenuItemWrapper = styled.div`
  height: 90%;
  overflow: auto;
`

export const EmptyMenuItem = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  border-radius: 0px 0px 5px 5px;

  @media (max-width: 768px) {
    height:27vh;
  }
`

export const MenuItem = styled.div`
  padding: 15px 10px;
  cursor: pointer;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  &:hover {
    background: ${theme.LIGHTBLUECOLOR};
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
