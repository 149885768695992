import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useGet from 'hooks/useGet'
import { IParamId } from 'interfaces/pages'
import APIS from 'constants/api'
import OrderTracking from 'views/orderTracking'
import { MainWrapper } from 'styles/pages/orderTrackingDetail'
import AdminPrivateLayout from 'components/Layouts/adminPrivateLayout'

const OrderTrackingDetail = () => {
  const { id }: IParamId = useParams()
  const { refetch: fetchTrackingDetails, data: trackingDetails } = useGet('all-tasksData', `${APIS.ALL_TASK}/${id}`)

  useEffect(() => {
    fetchTrackingDetails()
  }, [fetchTrackingDetails])

  return (
    <AdminPrivateLayout>
      <MainWrapper>
        <OrderTracking trackingDetails={trackingDetails} />
      </MainWrapper>
    </AdminPrivateLayout>
  )
}

export default OrderTrackingDetail
