import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_53_3765)">
      <path
        d="M2.66666 13.8333H5.33332L12.3333 6.83333C12.5084 6.65824 12.6473 6.45037 12.7421 6.22159C12.8368 5.99282 12.8856 5.74762 12.8856 5.5C12.8856 5.25238 12.8368 5.00718 12.7421 4.7784C12.6473 4.54963 12.5084 4.34176 12.3333 4.16666C12.1582 3.99157 11.9504 3.85268 11.7216 3.75791C11.4928 3.66315 11.2476 3.61438 11 3.61438C10.7524 3.61438 10.5072 3.66315 10.2784 3.75791C10.0496 3.85268 9.84175 3.99157 9.66666 4.16666L2.66666 11.1667V13.8333Z"
        stroke="#3091D0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 4.83334L11.6667 7.50001"
        stroke="#3091D0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_53_3765">
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
