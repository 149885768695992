import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0004 3.5999C10.1226 3.5999 8.60039 5.12213 8.60039 6.9999C8.60039 8.87767 10.1226 10.3999 12.0004 10.3999C13.8782 10.3999 15.4004 8.87767 15.4004 6.9999C15.4004 5.12213 13.8782 3.5999 12.0004 3.5999ZM7.40039 6.9999C7.40039 4.45939 9.45988 2.3999 12.0004 2.3999C14.5409 2.3999 16.6004 4.45939 16.6004 6.9999C16.6004 9.54041 14.5409 11.5999 12.0004 11.5999C9.45988 11.5999 7.40039 9.54041 7.40039 6.9999ZM5.62815 20.3999H18.3726C18.0702 17.1466 15.3327 14.5999 12.0004 14.5999C8.6681 14.5999 5.93063 17.1466 5.62815 20.3999ZM4.40039 20.9999C4.40039 16.8025 7.80303 13.3999 12.0004 13.3999C16.1978 13.3999 19.6004 16.8025 19.6004 20.9999C19.6004 21.3313 19.3318 21.5999 19.0004 21.5999H5.00039C4.66902 21.5999 4.40039 21.3313 4.40039 20.9999Z"
      fill="#181818"
      fillOpacity="0.4"
    />
  </svg>
)
export default SvgComponent
