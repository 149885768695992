import styled, { keyframes } from 'styled-components'
import { theme } from 'styles/theme'
interface IProps {
  status?: string | boolean
  activeIndex?: string
  variant?: 'contained' | 'Escalate' | 'Resolved' | 'disabled' | 'inProgress' | 'Pending' | 'Active' | 'Inactive'
}

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const TableWrapper = styled.div`
  .ant-table-row .ant-table-row-level-0 {
    cursor: pointer;
  }
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  width: 100%;
`
export const Heading = styled.div`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${theme.PRIMARYCOLOR};
`
export const DetailWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
  justify-content: space-around;
  &:last-child {
    border-bottom: none;
  }
`
export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
`
export const Title = styled.div`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.TITLECOLOR};
`
export const AddressTitle = styled.div`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: ${theme.GREYCOLOR};
`
export const Content = styled.div`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${theme.PRIMARYBLACKCOLOR};
  text-transform: capitalize;
`
export const AddressContent = styled.div`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${theme.PRIMARYBLACKCOLOR};
  text-transform: capitalize;
`
export const ActionWrapper = styled.div`
  display: flex;
  gap: 16px;
`
export const DriverInfoWrapper = styled.div`
  display: flex;
  gap: 10px;
  img {
    width: 32px !important;
  }
`
export const Name = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${theme.PRIMARYBLACKCOLOR};
`
export const UserRole = styled.div`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: ${theme.PRIMARYBLACKCOLOR};
`

export const StatusWrapper = styled.div<IProps>`
  max-width: 100px;
  width: 100%;
  padding: 4px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  border: 1.5px solid
    ${(props) =>
      props.status === 'Order-delivered'
        ? theme.SUCCESS
        : props.status === 'Cancelled'
        ? theme.ERROR
        : theme.PRIMARYCOLOR};
  color: ${(props) =>
    props.status === 'Order-delivered'
      ? theme.SUCCESS
      : props.status === 'Cancelled'
      ? theme.ERROR
      : theme.PRIMARYCOLOR};
`
export const TaskStatusWrapper = styled.div<IProps>`
  width: 165px;
  padding: 4px 10px;
  border-radius: 40px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;

  color: ${(props) =>
    props.status === 'Order-delivered'
      ? theme.PRIMARYGREENCOLOR
      : props.status === 'Cancelled'
      ? theme.ERROR
      : props.activeIndex === 'Pending'
      ? theme.NOTIFICATIONTEXTCOLOR
      : theme.PRIMARYCOLOR};
  background: ${(props) =>
    props.status === 'Order-delivered'
      ? theme.STATUSSUCCESSBACKGROUNDCOLOR
      : props.status === 'Cancelled'
        ? theme.STATUSERRORBACKGROUNDCOLOR
        : props.activeIndex === 'Pending'
          ? theme.NOTIFICATIONYELLOWCOLOR
          : props.status === 'Searching-for-Agent' ?  theme.NOTIFICATIONYELLOWCOLOR : theme.STATUSBLUECOLOR};
`
export const MobileTaskStatusWrapper = styled.div`
@media (max-width: 767px) {
  > div {
    width: 120px;
    text-align: left;
  }
  }
 
`
export const TaskStatusUnAssignedWrapper = styled.div<IProps>`
  width: 130px;
  padding: 4px 10px;
  border-radius: 40px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;

  color: ${(props) =>
    props.status === 'Order-delivered'
      ? theme.PRIMARYGREENCOLOR
      : props.status === 'Cancelled'
      ? theme.ERROR
      : props.activeIndex === 'Pending'
      ? theme.NOTIFICATIONTEXTCOLOR
      : theme.PRIMARYCOLOR};
  background: ${(props) =>
    props.status === 'Order-delivered'
      ? theme.STATUSSUCCESSBACKGROUNDCOLOR
      : props.status === 'Cancelled'
      ? theme.STATUSERRORBACKGROUNDCOLOR
      : props.activeIndex === 'Pending'
      ? theme.NOTIFICATIONYELLOWCOLOR
      : theme.STATUSBLUECOLOR};
`
export const TaskStatusImage = styled.div<IProps>`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: ${(props) =>
    props.status === 'Order-delivered'
      ? theme.PRIMARYGREENCOLOR
      : props.status === 'Cancelled'
        ? theme.ERROR
        : props.activeIndex === 'Pending'
          ? theme.STATUS_NOTIFICATIONCOLOR
          : props.status === 'Searching-for-Agent' ?  theme.STATUS_NOTIFICATIONCOLOR : theme.PRIMARYCOLOR};
`
export const TaskStatusText = styled.div<IProps>`
  font-family: 'Inter';
  color: ${(props) =>
    props.status === 'Order-delivered'
      ? theme.PRIMARYGREENCOLOR
      : props.status === 'Cancelled'
        ? theme.ERROR
        : props.activeIndex === 'Pending'
          ? theme.NOTIFICATIONTEXTCOLOR 
          : props.status === 'Searching-for-Agent' ?  theme.NOTIFICATIONTEXTCOLOR : theme.PRIMARYCOLOR};
`
export const IssueImageWrapper = styled.div<IProps>`
  height: 8px;
  width: 8px;
  border-radius: 50%;

  background: ${(props) =>
    props.status == 'CLOSED' ? theme.ERROR : props.status == 'RESOLVED' ? theme.PRIMARYGREENCOLOR : theme.PRIMARYCOLOR};
`
export const IssueStatusText = styled.div<IProps>`
  text-transform: lowercase;
  ::first-letter {
    text-transform: uppercase;
  }
  color: ${(props) =>
    props.status == 'CLOSED' ? theme.ERROR : props.status == 'RESOLVED' ? theme.PRIMARYGREENCOLOR : theme.PRIMARYCOLOR};
`

export const ActionStatusWrapper = styled.div<IProps>`
  max-width: 85px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 4px 8px;
  border-radius: 40px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  border: none;
  color: ${(props) =>
    props.status == 'CLOSED' ? theme.ERROR : props.status == 'RESOLVED' ? theme.PRIMARYGREENCOLOR : theme.PRIMARYCOLOR};
  background: ${(props) =>
    props.status == 'CLOSED'
      ? theme.STATUSERRORBACKGROUNDCOLOR
      : props.status == 'RESOLVED'
      ? theme.STATUSSUCCESSBACKGROUNDCOLOR
      : theme.STATUSBLUECOLOR};
`
export const PopoverWrapper = styled.div`
  .ant-popover-content {
    width: 100px !important;
  }
  .ant-popover .ant-popover-inner {
    width: 100%;
    max-width: 120px;
  }
`
export const DriverStatusConatiner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: max-content;
`
export const DriverImageWrapper = styled.div<IProps>`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: ${(props) =>
    props.status == '1' ? theme.PRIMARYGREENCOLOR : props.status == '2' ? theme.ERROR : theme.WARNING};
`
export const DriverStatusTextWrapper = styled.div<IProps>`
  color: ${(props) =>
    props.status == '1' ? theme.PRIMARYGREENCOLOR : props.status == '2' ? theme.ERROR : theme.WARNING};
`

export const DriverStatusWrapper = styled(StatusWrapper)<IProps>`
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  width: 70px;
  padding: 4px 8px;
  background: ${(props) =>
    props.status == '1'
      ? theme.STATUSSUCCESSBACKGROUNDCOLOR
      : props.status == '2'
      ? theme.ERROR
      : theme.NOTIFICATIONYELLOWCOLOR};
  color: ${(props) =>
    props.status == '1' ? theme.PRIMARYGREENCOLOR : props.status == '2' ? theme.ERROR : theme.WARNING};
`
export const DriverStateWrapper = styled(StatusWrapper)<IProps>`
  border: 1.5px solid
    ${(props) =>
      props.status == 'Soft Lock'
        ? theme.PRIMARYGREENCOLOR
        : props.status == 'Available'
        ? theme.PRIMARYCOLOR
        : props.status == 'Locked'
        ? theme.WARNING
        : theme.ERROR};
  color: ${(props) =>
    props.status == 'Soft Lock'
      ? theme.PRIMARYGREENCOLOR
      : props.status == 'Available'
      ? theme.PRIMARYCOLOR
      : props.status == 'Locked'
      ? theme.WARNING
      : theme.ERROR};
`
export const BlockDriverStatusWrapper = styled(StatusWrapper)<IProps>`
  border-radius: 40px;
  border: none;
  color: ${(props) => props.status == true && theme.ERROR};
`
export const AdminImageWrapper = styled.div<IProps>`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: ${(props) => (props.status == '1' ? theme.PRIMARYGREENCOLOR : theme.ERROR)};
`

export const AdminStatusTextWrapper = styled.div<IProps>`
  color: ${(props) => (props.status == '1' ? theme.PRIMARYGREENCOLOR : theme.ERROR)};
`
export const AdminStatusWrapper = styled(StatusWrapper)<IProps>`
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 70px;
  padding: 4px 8px;
  border-radius: 40px;
  color: ${(props) => (props.status == '1' ? theme.PRIMARYGREENCOLOR : theme.ERROR)};
  background: ${(props) =>
    props.status == '1' ? theme.STATUSSUCCESSBACKGROUNDCOLOR : theme.STATUSERRORBACKGROUNDCOLOR};
`

export const HubImageWrapper = styled.div<IProps>`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: ${(props) => (props.status == 'Active' ? theme.PRIMARYGREENCOLOR : theme.ERROR)};
`
export const HubStatusTextWrapper = styled.div<IProps>`
  color: ${(props) => (props.status == 'Active' ? theme.PRIMARYGREENCOLOR : theme.ERROR)};
`

export const HubStatusWrapper = styled(StatusWrapper)<IProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  padding: 4px 8px;
  width: 70px;
  border: none;
  color: ${(props) => (props.status == 'Active' ? theme.PRIMARYGREENCOLOR : theme.ERROR)};
  background: ${(props) =>
    props.status == 'Active' ? theme.STATUSSUCCESSBACKGROUNDCOLOR : theme.STATUSERRORBACKGROUNDCOLOR};
`

const glowing = keyframes`
  0% {
    background-color: #2ba805;
    box-shadow: 0 0 5px #2ba805;
    opacity: 1;
  }
  50% {
    background-color: #49e819;
    box-shadow: 0 0 5px #49e819;
    opacity: 1;
  }
  100% {
    background-color: #2ba805;
    box-shadow: 0 0 5px #2ba805;
    opacity: 1;
  }
`
const offGlowing = keyframes`
 0% {
    background-color: ${theme.ERROR};
    box-shadow: 0 0 5px ${theme.ERROR};
    opacity: 1;
  }
  50% {
    background-color: ${theme.ERROR};
    box-shadow: 0 0 5px ${theme.ERROR};
    opacity: 0.5;

  }
  100% {
    background-color:${theme.ERROR};
    box-shadow: 0 0 5px${theme.ERROR};
    opacity: 1;

  }
`
export const OuterCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${theme.STATUSSUCCESSBACKGROUNDCOLOR};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow to the outer circle */
`
export const OfflineOuterCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${theme.STATUSERRORBACKGROUNDCOLOR};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow to the outer circle */
`
export const StatusButton = styled.div`
  max-width: 8px;
  width: 100%;
  height: 8px;
  border-radius: 50%;
  background: ${theme.SUCCESS};
  animation: ${glowing} 2s linear infinite;
  cursor: pointer;
`
export const StatusOffline = styled(StatusButton)`
  background: ${theme.ERROR};
  animation: ${offGlowing} 2s linear infinite;
`

export const PopOverTitle = styled.div`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${theme.PRIMARYBLACKCOLOR};
`
export const QuantityWrapper = styled.div`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${theme.BOXCOLOR};
`
export const NumberWrapper = styled.div`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.PRIMARYBLACKCOLOR};
`
export const NumberContainer = styled.div`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${theme.PRIMARYBLACKCOLOR};
`
export const ButtonWrapper = styled.div`
  button {
    max-width: 124px;
    height: 38px;
    font-size: 14px;
  }
`
