import ForgotPasswordSection from 'views/forgotPassword'
import CreatePasswordImage from 'assets/images/forgot_password.png'
import {
  LoginMainContainer,
  PasswordLeftSection,
  ImageSection,
  WecomeInformation,
  ImageWrapSection,
} from 'styles/views/signin'
import { Logo, LogoSection, LogoText } from 'styles/components/Navbar'
import logo from 'assets/images/proteanLogo.svg'
import proteanText from 'assets/images/proteanText.svg'

const ForgotPassword = () => (
  <LoginMainContainer>
    <PasswordLeftSection>
      <ImageSection>
        <LogoSection>
          <img src={logo} alt="protean" />
          <Logo>
            <img src={proteanText} alt="protean" width={97} height={22} />
            <LogoText>Logistics App</LogoText>
          </Logo>
        </LogoSection>

        <WecomeInformation>
          <ImageWrapSection>
            <img src={CreatePasswordImage} alt="LoginImage" />
          </ImageWrapSection>
        </WecomeInformation>
      </ImageSection>
    </PasswordLeftSection>
    <ForgotPasswordSection />
  </LoginMainContainer>
)

export default ForgotPassword
