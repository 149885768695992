// import { Breadcrumb } from 'antd'
import AdminPrivateLayout from 'components/Layouts/adminPrivateLayout'
import DriverProfileDetails from 'views/driverProfileDetails'
import {
  IssuesMainWrapper,
  HeadingWrapper,
  //  BreadcrumbWrapper,
  MainHeading,
} from 'styles/pages/gpsTracker'
import { ImageWrapper } from 'styles/views/dashboard'
import PrevIcon from 'assets/images/arrow-left.png'

const DriverProfile = () => {
  // const DriverBreadcrumb: React.FC = () => (
  //   <Breadcrumb
  //     separator=">"
  //     items={[
  //       {
  //         title: 'Drivers',
  //         href: '/driver',
  //       },
  //       {
  //         title: 'Driver’s Profile',
  //       },
  //     ]}
  //   />
  // )
  return (
    <AdminPrivateLayout>
      <IssuesMainWrapper>
        <HeadingWrapper>
          {/* <BreadcrumbWrapper>
            <DriverBreadcrumb />
          </BreadcrumbWrapper> */}
          <MainHeading>
            <ImageWrapper style={{ maxWidth: 'unset' }}>
              <img src={PrevIcon} alt="agent-image" onClick={() => history.back()} />
            </ImageWrapper>
            Driver&apos;s Profile
          </MainHeading>
        </HeadingWrapper>
        <DriverProfileDetails />
      </IssuesMainWrapper>
    </AdminPrivateLayout>
  )
}

export default DriverProfile
