const SvgComponent = () => (
  <svg width="32" height="22" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25 15H5C4.73478 15 4.48043 15.1054 4.29289 15.2929C4.10536 15.4804 4 15.7348 4 16C4 16.2652 4.10536 16.5196 4.29289 16.7071C4.48043 16.8946 4.73478 17 5 17H25C25.2652 17 25.5196 16.8946 25.7071 16.7071C25.8946 16.5196 26 16.2652 26 16C26 15.7348 25.8946 15.4804 25.7071 15.2929C25.5196 15.1054 25.2652 15 25 15Z"
      fill="black"
    />
    <path
      d="M24.5862 16L16.2922 24.292C16.1044 24.4798 15.9989 24.7344 15.9989 25C15.9989 25.2655 16.1044 25.5202 16.2922 25.708C16.4799 25.8958 16.7346 26.0012 17.0002 26.0012C17.2657 26.0012 17.5204 25.8958 17.7082 25.708L26.7082 16.708C26.8013 16.6151 26.8752 16.5047 26.9256 16.3832C26.976 16.2618 27.002 16.1315 27.002 16C27.002 15.8684 26.976 15.7382 26.9256 15.6167C26.8752 15.4952 26.8013 15.3849 26.7082 15.292L17.7082 6.29198C17.5204 6.10421 17.2657 5.99872 17.0002 5.99872C16.7346 5.99872 16.4799 6.10421 16.2922 6.29198C16.1044 6.47975 15.9989 6.73443 15.9989 6.99998C15.9989 7.26553 16.1044 7.52021 16.2922 7.70798L24.5862 16Z"
      fill="black"
    />
  </svg>
)
export default SvgComponent
