import styled from 'styled-components'
import { theme } from 'styles/theme'

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 203px !important;
  border-radius: 4px;
  a {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-decoration: none;
    width: 100%;
    color: ${theme.PRIMARYBLACKCOLOR};
  }

  a.active {
    color: ${theme.ORANGERUST};
  }
`
export const Title = styled.div`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.PRIMARYBLACKCOLOR};
  cursor: pointer;
`
export const NavbarContainer = styled.div`
  padding: 10px;
  background: #ffffff;
  display: flex;

  &.navbar-container {
    justify-content: space-between;
  }
`
export const LeftSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 200px;
  padding: 0 11px;
  @media (max-width: 768px) {
    max-width:175px; 
  }
`
export const LogoSection = styled.div`
  display: flex;
  gap: 5px;
  cursor: pointer;
`
export const NameSection = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 16px;
  font-family: 'Inter';
  color: ${theme.PRIMARYBLACKCOLOR};
  @media (max-width: 768px) {
    display: none;
  }
`
export const RightSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding: 0 40px 0 60px;

  .ant-input-affix-wrapper-focused {
    border: 1px solid #b9b9b9 !important;
    box-shadow: none;
  }
  .ant-input-affix-wrapper {
    padding: 0 3px 0 5px !important;
    width: 348px;
  }
  .ant-input-prefix {
    height: 44px;
  }
  &.right-container {
    width: unset;
    padding: 0;
  }
`

export const UserSection = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  svg {
    cursor: pointer;
  }

  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
    background-color: white;
  }
`
export const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`
export const OptionsWrapper = styled.div`
  display: flex;
  svg {
    cursor: pointer;
  }
`
export const UserRole = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${theme.GREYCOLOR};
  margin-top: 5px;
  text-transform: capitalize;
  font-weight: 400;
`
export const Username = styled.div`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  color: ${theme.PRIMARYBLACKCOLOR};
  text-transform: capitalize;
  width: max-content;
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: max-content !important;
  border-radius: 4px;
`

export const StyledTableWrapper = styled.div`
  .ant-table-pagination {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .ant-pagination {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
  }

  .ant-pagination-item-active {
    background: ${theme.PAGINATIONBACKGRONDCOLOR};
    border: none;
  }

  .ant-table-thead > tr > th {
    background: transparent !important;
    font-family: Gilroy-Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.006em;
    text-align: left;
    color: rgba(110, 124, 135, 1) !important;
  }
  .ant-table-tbody > tr > td {
    color: ${theme.PRIMARYBLACKCOLOR} !important;
    font-weight: 400 !important;
    div {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.006em;
      text-align: left;
    }
  }
  .ant-table-cell {
    padding: 14px !important;
  }
  .ant-pagination-options {
    display: none;
  }
  .ant-table-wrapper .ant-table-thead > tr > td {
    background: transparent !important;
  }

  .prev-button {
    position: absolute;
    left: 25px;
    color: ${theme.PRIMARYBLACKCOLOR};
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    user-select: none;
    background: white;
    padding: 8px 14px;
    border: 1px solid ${theme.PAGINATION_BUTTON_BORDER};
    border-radius: 10px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .next-button {
    position: absolute;
    right: 25px;
    color: ${theme.PRIMARYBLACKCOLOR};
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    user-select: none;
    padding: 8px 14px;
    background: white;
    border: 1px solid ${theme.PAGINATION_BUTTON_BORDER};
    border-radius: 10px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .ant-table-body {
    overflow-x: auto;
  }

  .ant-table-body::-webkit-scrollbar {
    height: 0px;
  }
  .ant-table-wrapper tr.ant-table-expanded-row > td {
    background: white;
  }
  .ant-table-wrapper tr.ant-table-expanded-row:hover > td {
    background: rgba(250, 250, 250, 1);
  }
  .ant-table-body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .ant-table-body::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }

  .custom-pagination {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    span {
      margin: 0 10px;
      font-size: 14px;
      font-weight: 600;
    }
  }
`
export const Name = styled.div`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.PRIMARYBLACKCOLOR};
`
export const Description = styled.span`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.LIGHTGREYCOLOR};
  margin-left: 5px;
`

export const Logo = styled.div`
  display: flex;
  flex-direction: column;
`

export const LogoText = styled.div`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.PRIMARYBLACKCOLOR};
`