// import { Breadcrumb } from 'antd'
import AdminPrivateLayout from 'components/Layouts/adminPrivateLayout'
import IssueSummarySection from 'views/issueSummarySection'
import { IssuesMainWrapper, BreadcrumbWrapper, MainHeading, IssueWrapper } from 'styles/pages/gpsTracker'
import Button from 'components/Button'
import { useHistory, useParams } from 'react-router-dom'
import { GPSIssueTrack } from 'constants/routes'
import useGet from 'hooks/useGet'
import APIS from 'constants/api'
import { IParamId } from 'interfaces/pages'
import { ImageWrapper } from 'styles/views/dashboard'
import PrevIcon from 'assets/images/arrow-left.png'


const IssueSummary = () => {
  const router = useHistory()
  const { id }: IParamId = useParams()
  const { data: singleIssueDetail } = useGet('get-issues', `${APIS.GET_ISSUE}/${id}`)
  // const TaskBreadcrumb: React.FC = () => (
  //   <Breadcrumb
  //     separator=">"
  //     items={[
  //       {
  //         title: 'Issues',
  //         href: '/complaints',
  //       },
  //       {
  //         title: 'Issue Summary',
  //       },
  //     ]}
  //   />
  // )

  return (
    <AdminPrivateLayout>
      <IssuesMainWrapper>
        <IssueWrapper>
          <BreadcrumbWrapper>
            {/* <TaskBreadcrumb /> */}
            <MainHeading>
              
            <ImageWrapper style={{ maxWidth: 'unset' }}>
              <img src={PrevIcon} alt="agent-image" onClick={() => history.back()} />
            </ImageWrapper>
              Issue Summary</MainHeading>
          </BreadcrumbWrapper>
          <Button
            label="Order Detail"
            variant="contained"
            type="submit"
            onClick={() => {
              router.push(`${GPSIssueTrack.path.replace(':id', singleIssueDetail?.data?.issue?.transaction_id)}`)
            }}
          />
        </IssueWrapper>
        <IssueSummarySection />
      </IssuesMainWrapper>
    </AdminPrivateLayout>
  )
}

export default IssueSummary
