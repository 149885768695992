import { SVGProps } from 'react'

const GroupIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity={0.4} clipPath="url(#clip0_66_3376)">
      <path
        d="M18 21C18 21.5304 18.2107 22.0391 18.5858 22.4142C18.9609 22.7893 19.4696 23 20 23C20.5304 23 21.0391 22.7893 21.4142 22.4142C21.7893 22.0391 22 21.5304 22 21C22 20.4696 21.7893 19.9609 21.4142 19.5858C21.0391 19.2107 20.5304 19 20 19C19.4696 19 18.9609 19.2107 18.5858 19.5858C18.2107 19.9609 18 20.4696 18 21Z"
        stroke=""
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 29V28C16 27.4696 16.2107 26.9609 16.5858 26.5858C16.9609 26.2107 17.4696 26 18 26H22C22.5304 26 23.0391 26.2107 23.4142 26.5858C23.7893 26.9609 24 27.4696 24 28V29"
        stroke=""
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 13C23 13.5304 23.2107 14.0391 23.5858 14.4142C23.9609 14.7893 24.4696 15 25 15C25.5304 15 26.0391 14.7893 26.4142 14.4142C26.7893 14.0391 27 13.5304 27 13C27 12.4696 26.7893 11.9609 26.4142 11.5858C26.0391 11.2107 25.5304 11 25 11C24.4696 11 23.9609 11.2107 23.5858 11.5858C23.2107 11.9609 23 12.4696 23 13Z"
        stroke=""
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 18H27C27.5304 18 28.0391 18.2107 28.4142 18.5858C28.7893 18.9609 29 19.4696 29 20V21"
        stroke=""
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 13C13 13.5304 13.2107 14.0391 13.5858 14.4142C13.9609 14.7893 14.4696 15 15 15C15.5304 15 16.0391 14.7893 16.4142 14.4142C16.7893 14.0391 17 13.5304 17 13C17 12.4696 16.7893 11.9609 16.4142 11.5858C16.0391 11.2107 15.5304 11 15 11C14.4696 11 13.9609 11.2107 13.5858 11.5858C13.2107 11.9609 13 12.4696 13 13Z"
        stroke=""
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 21V20C11 19.4696 11.2107 18.9609 11.5858 18.5858C11.9609 18.2107 12.4696 18 13 18H15"
        stroke=""
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_66_3376">
        <rect width={24} height={24} fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
)

export default GroupIcon
