import RequestLogDetail from 'views/requestLog'
import AdminPrivateLayout from 'components/Layouts/adminPrivateLayout'

const RequestLogs = () => (
  <AdminPrivateLayout>
    <RequestLogDetail />
  </AdminPrivateLayout>
)

export default RequestLogs
