import { SVGProps } from 'react'

const OrderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity={0.4} clipPath="url(#clip0_66_3385)">
      <path
        d="M20 11L28 15.5V24.5L20 29L12 24.5V15.5L20 11Z"
        stroke=""
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M20 20L28 15.5" stroke="" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 20V29" stroke="" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 20L12 15.5" stroke="" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24 13.25L16 17.75" stroke="" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_66_3385">
        <rect width={24} height={24} fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
)

export default OrderIcon
