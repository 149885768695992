import styled from 'styled-components'
import { theme } from 'styles/theme'

export const Container = styled.div``
export const Wrapper = styled.div`
  display: grid;
  background-color: ${theme.WHITE};
  padding: 10px 20px 0 10px;
  grid-gap: 52px;
  text-align: left;
  width: 50px;
  height: 100%;
  // min-height: calc(100vh - 93px);
  border-right: 1px solid ${theme.GRAYCOLOR};
  @media (max-width: 1024px) {
    width: 50px;
    padding: 10px 10px 0 10px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`
export const BasicLinksContainer = styled.div``
export const DataWrapper = styled.div`
  max-width: 180px;
  min-height: 44px;
  display: flex;
  grid-gap: 10px;
  align-items: center;
  padding: 2px 0;
  cursor: pointer;
  .mobile-navbar {
    color: ${theme.PRIMARYBLACKCOLOR};
  }
  a {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    color: ${theme.WHITE};
    width: 100%;
    max-width: 180px;
    padding: 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      stroke: ${theme.LIGHTGREYCOLOR};
    }
    &.active svg {
      stroke: ${theme.SECONDARYBLUECOLOR};
    }
  }
  &.first-item a svg {
    stroke: 0.1;
  }

  &.first-item a.active svg {
    stroke: ${theme.SECONDARYBLUECOLOR};
  }

  @media (max-width: 1024px) {
    a {
      max-width: 110px;
    }
    &.mobile-navbar {
      a {
        max-width: unset;
      }
    }
  }
`
export const NavItems = styled.div`
  &.mobile-navbar {
    color: ${theme.PRIMARYBLACKCOLOR};
    display: flex;
    align-items: center;
  }
  color: ${theme.WHITE};
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  font-family: 'Inter';
  text-transform: capitalize;
  white-space: nowrap;
  margin-bottom: 2px;
  text-decoration: none;
`
export const Title = styled.div``
