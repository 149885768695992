import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="42" height="40" rx="6" fill="#3091D0" />
    <g clipPath="url(#clip0_453_13148)">
      <path
        d="M15 23.75V25.25C15 25.6478 15.158 26.0294 15.4393 26.3107C15.7206 26.592 16.1022 26.75 16.5 26.75H25.5C25.8978 26.75 26.2794 26.592 26.5607 26.3107C26.842 26.0294 27 25.6478 27 25.25V23.75"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 19.25L21 23L24.75 19.25"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M21 14V23" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_453_13148">
        <rect width="18" height="18" fill="white" transform="translate(12 11)" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
