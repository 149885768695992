import React, { useState } from 'react'
// import { Breadcrumb } from 'antd'
import moment from 'moment'
import { IOrderTrackingProps } from 'interfaces/views'
import OrderStepper from 'views/orderTracking/orderTracker'
import OrderTable from 'views/orderTracking/table'
import {
  TrackingDetailWrapper,
  HeadingWrapper,
  MainHeading,
  DetailWrapper,
  OrderInformationWrapper,
  OrderDetailWrapper,
  TrackingID,
  OrderPlacementDate,
  StatusWrapper,
  Title,
  OrderWrapper,
  DetailsInfo,
  TaskStatusImage,
  TaskStatusText,
  TaskStatusWrapper,
  AdminButtonWrapper,
} from 'styles/views/orderTracking'
import Button from 'components/Button'
import Modal from 'components/Modal'
import LiveTrackingModal from 'views/liveTrackingmModal'
// import { useHistory } from 'react-router-dom'
import PrevIcon from 'assets/images/arrow-left.png'

const OrderTracking: React.FC<IOrderTrackingProps> = ({ trackingDetails }) => {
  const [open, setOpen] = useState(false)

  // const router = useHistory()

  // const TaskBreadcrumb: React.FC = () => (
  //   <Breadcrumb separator=">">
  //     <Breadcrumb.Item onClick={() => router.goBack()}>Order</Breadcrumb.Item>
  //     <Breadcrumb.Item>Order-Tracking</Breadcrumb.Item>
  //   </Breadcrumb>
  // )

  const details = trackingDetails?.data?.taskStatus
  const taskStatusDetails = trackingDetails?.data?.taskStatus || []
  const items = trackingDetails?.data?.task?.items || []
  const taskType = items[0]?.descriptor?.code

  return (
    <>
      <TrackingDetailWrapper>
        {/* <TaskBreadcrumb /> */}
        <HeadingWrapper>
          <MainHeading>
            <img src={PrevIcon} alt="back-button" onClick={() => history.back()} />
            Tracking Details
          </MainHeading>
          <AdminButtonWrapper>
            <Button type="button" label="Live Tacking" variant="contained" onClick={() => setOpen(true)} />
          </AdminButtonWrapper>
        </HeadingWrapper>
        <DetailWrapper className="detail-wrapper">
          <DetailsInfo className="details-info">
            <OrderInformationWrapper>
              <OrderDetailWrapper>
                <TrackingID>{trackingDetails?.data?.task?.task_id}</TrackingID>
                <OrderPlacementDate>
                  Ordered on {moment(trackingDetails?.data?.task?.createdAt).format('DD MMM YYYY')}
                </OrderPlacementDate>
              </OrderDetailWrapper>
              <StatusWrapper>
                <Title>Status</Title>
                {taskType && taskType === 'P2H2P' ? (
                  <TaskStatusWrapper
                    status={
                      taskStatusDetails[taskStatusDetails.length - 1].status === 'Order-delivered'
                        ? 'Completed'
                        : taskStatusDetails[taskStatusDetails.length - 1].status
                    }
                  >
                    {taskStatusDetails[taskStatusDetails.length - 1].status === 'Order-delivered'
                      ? 'Completed'
                      : taskStatusDetails[taskStatusDetails.length - 1].status}
                  </TaskStatusWrapper>
                ) : (
                  <TaskStatusWrapper status={trackingDetails?.data?.task?.status}>
                    <TaskStatusImage status={trackingDetails?.data?.task?.status} />
                    <TaskStatusText status={trackingDetails?.data?.task?.status}>
                      {trackingDetails?.data?.task?.status}
                    </TaskStatusText>
                  </TaskStatusWrapper>
                )}
              </StatusWrapper>
            </OrderInformationWrapper>

            <OrderWrapper className="tracking-details">
              <OrderStepper trackingDetails={trackingDetails} />
              <OrderTable details={details} />
            </OrderWrapper>
          </DetailsInfo>
        </DetailWrapper>
      </TrackingDetailWrapper>
      <Modal isOpen={open}>
        <LiveTrackingModal showModal={(action: boolean) => setOpen(action)} trackingDetails={trackingDetails} />
      </Modal>
    </>
  )
}

export default OrderTracking
