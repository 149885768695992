import agentImage from 'assets/images/select_image.png'
import { Logo, LogoText } from 'styles/components/Navbar'
import { LeftSection, LogoContainer, ImageWrapper, LogoWrapper } from 'styles/views/inviteScreen'
import logo from 'assets/images/proteanLogo.svg'
import proteanText from 'assets/images/proteanText.svg'

const AgentLeftSection = () => (
  <LeftSection>
    <LogoContainer>
      <LogoWrapper>
        <img src={logo} alt="protean" />
        <Logo>
          <img src={proteanText} alt="protean" width={97} height={22} />
          <LogoText>Logistics App</LogoText>
        </Logo>
      </LogoWrapper>
    </LogoContainer>

    <ImageWrapper>
      <img src={agentImage} alt="agent-image" />
    </ImageWrapper>
  </LeftSection>
)

export default AgentLeftSection
