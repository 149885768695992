import { useContext, useEffect, useState } from 'react'
import { Drawer, Menu } from 'antd'
import { useHistory } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { Dropdown, Space, Popover } from 'antd'
import moment from 'moment'
import { DashboardRoute, SettingsRoute } from 'constants/routes'
import APIS from 'constants/api'
import { AppContext } from 'context/payloadContext'
import useGet from 'hooks/useGet'
import Modal from 'components/Modal'
import LogoutConfirmaionModal from 'views/logoutConfirmaionModal'
import AvatarImage from 'assets/images/avatar_image.png'
import BellIcon from 'assets/svg/BellIcon'
import logo from 'assets/images/proteanLogo.svg'
import proteanText from 'assets/images/proteanText.svg'
import {
  ContentContainer,
  Title,
  NavbarContainer,
  LogoSection,
  NameSection,
  LeftSection,
  RightSection,
  UserSection,
  ProfileSection,
  UserRole,
  Username,
  Name,
  Description,
  Logo,
  LogoText,
} from 'styles/components/Navbar'
import { NotifiactionWrapper, NotificationTitle, TimeStamp } from 'styles/views/driverFlowHome'
import { useMediaQuery } from 'hooks/useMobile'
import MENU from 'constants/menu'
import ROLES from 'constants/role'
import { DataWrapper, NavItems } from 'styles/components/Sidebar'
import { MenuOutlined } from '@ant-design/icons'

const Navbar = () => {
  const { userInfo } = useContext(AppContext)
  const [successModal, setSuccessModal] = useState(false)

  const { refetch, data: notifyData } = useGet('get-notification', `${APIS.GET_NOTIFICATIONS}`)
  const router = useHistory()

  useEffect(() => {
    refetch()
  }, [])

  const content = (
    <ContentContainer>
      <Title
        onClick={() => {
          router.push(`${SettingsRoute.path}`)
        }}
      >
        <NavLink className="title" to={`${SettingsRoute.path}`}>
          Settings
        </NavLink>
      </Title>
      <Title onClick={() => setSuccessModal(true)}>Logout</Title>
    </ContentContainer>
  )

  const [openMenu, setOpenMenu] = useState(false)

  const isMobile = useMediaQuery('(max-width: 768px)')

  return (
    <NavbarContainer className={isMobile ? 'navbar-container' : ''}>
      <LeftSection>
        {isMobile && (
          <>
            <div>
              <MenuOutlined onClick={() => setOpenMenu(true)} />
            </div>
            <Drawer
              placement="left"
              className="custom-menu"
              open={openMenu}
              onClose={() => setOpenMenu(false)}
              closable={false}
            >
              <Menu className="custom-menu">
                {MENU[ROLES.ADMIN].map((item, index) => (
                  <DataWrapper key={index} className={index === 0 ? 'first-item mobile-navbar' : 'mobile-navbar'}>
                    <NavLink className="title" to={`${item?.link}`}>
                      <NavItems className="mobile-navbar">
                        {item?.icon} {item?.title}
                      </NavItems>
                    </NavLink>
                  </DataWrapper>
                ))}
              </Menu>
            </Drawer>
          </>
        )}
        <LogoSection
          onClick={() => {
            router.push(`${DashboardRoute.path}`)
          }}
        >
          <img src={logo} alt="protean" />
          <Logo>
            <img src={proteanText} alt="protean" width={97} height={22} />
            <LogoText>Logistics App</LogoText>
          </Logo>
        </LogoSection>
      </LeftSection>
      <RightSection className={isMobile ? 'right-container' : ''}>
        <NameSection>
          <Name> Hey {userInfo?.name},</Name>
          <Description> {`here’s what’s happening with your store today`}</Description>
        </NameSection>

        <UserSection>
          <Dropdown
            menu={
              {
                items: notifyData?.data?.map((e: any, index: number) => {
                  return {
                    label: (
                      <NotifiactionWrapper>
                        <NotificationTitle>{e?.text}</NotificationTitle>
                        <TimeStamp>{moment(e?.createdAt).startOf('hour').fromNow()}</TimeStamp>
                      </NotifiactionWrapper>
                    ),
                    key: index,
                  }
                }),
              } || [
                {
                  key: '1',
                  label: <NotificationTitle>Data Not Found</NotificationTitle>,
                },
              ]
            }
            trigger={['click']}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <BellIcon />
              </Space>
            </a>
          </Dropdown>

          <Popover placement="bottomLeft" content={content} trigger="hover">
            <ProfileSection>
              <img src={AvatarImage} alt="avatar" />
              {!isMobile && (
                <Username>
                  {userInfo?.name} <UserRole>{userInfo?.role?.name}</UserRole>
                </Username>
              )}
            </ProfileSection>
          </Popover>
        </UserSection>
      </RightSection>
      <Modal isOpen={successModal}>
        <LogoutConfirmaionModal showModal={(value: boolean) => setSuccessModal(value)} />
      </Modal>
    </NavbarContainer>
  )
}

export default Navbar
