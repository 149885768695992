import { useEffect, useRef, useState } from 'react'
import { Steps } from 'antd'
import KYCDetails from 'views/inviteAgentScreen/agentKYCDetailSection'
import KYCDocuments from 'views/inviteAgentScreen/agentKYCDocuments'
import AgentBankDetails from 'views/inviteAgentScreen/bankDetailSection'
import VehicleDetails from 'views/vehicleDetails'
import DriverDetails from 'views/inviteAgentScreen/driverDetailsSection'
import { InviteAgentWrapper, StepsWrapper, StepsContainer, AgentInfoWrapper } from 'styles/pages/inviteAgentScreen'

import { MainHeading, SubHeading, HeadingWrapper } from 'styles/views/inviteAgentScreen/driverDetailsSection'
import PrevIcon from 'assets/images/arrow-left.png'
import AdminPrivateLayout from 'components/Layouts/adminPrivateLayout'

const InviteAgent = () => {
  const scrollRef = useRef<HTMLDivElement>(null)

  const [currentStep, setCurrentStep] = useState(0)

  const next = () => {
    setCurrentStep(currentStep + 1)
  }
  // const prev = () => {
  //   setCurrentStep(currentStep - 1)
  // }

  // const router = useHistory()
  // const handleBack = () => {
  //   if (currentStep === 0) {
  //     router.goBack()
  //   } else {
  //     prev()
  //   }
  // }

  const items = [
    {
      step: 0,
      title: 'Step 1',
      description: 'Driver Details',
      component: <DriverDetails next={next} ref={scrollRef} />,
      componentTitle: 'Add Driver',
      componentDescription: 'Please provide the personal details in the form below.',
    },
    {
      step: 1,
      title: 'Step 2',
      description: 'Vehicle Details',
      component: <VehicleDetails next={next} />,
      componentTitle: 'Vehicle Details',
      componentDescription: 'Complete information about the vehicle, including make, model, and registration.',
    },
    {
      step: 2,
      title: 'Step 3',
      description: 'KYC Details',
      component: <KYCDetails next={next} />,
      componentTitle: 'Add KYC Details',
      componentDescription: 'Submit and update your Know Your Customer (KYC) information effortlessly.',
    },
    {
      step: 3,
      title: 'Step 4',
      description: 'KYC Documents',
      component: <KYCDocuments next={next} />,
      componentTitle: 'Add KYC Documents',
      componentDescription: 'Upload essential KYC documents with ease for verification.',
    },
    {
      step: 4,
      title: 'Step 5',
      description: 'Bank Details',
      component: <AgentBankDetails />,
      componentTitle: 'Add Bank Details',
      componentDescription: 'Securely input and manage your bank account information',
    },
  ]

  const getComponentByStep = (step: number) => {
    const stepObj = items.find((obj) => obj.step === step)
    return stepObj ? stepObj.component : null
  }

  const getComponentTitleByStep = (step: number) => {
    const stepObj = items.find((obj) => obj.step === step)
    return stepObj ? stepObj.componentTitle : ''
  }

  const getComponentInfoByStep = (step: number) => {
    const stepObj = items.find((obj) => obj.step === step)
    return stepObj ? stepObj.componentDescription : ''
  }

  useEffect(() => {
    if (scrollRef) {
      scrollRef?.current?.focus({
        preventScroll: false,
      })
    }
  }, [])

  return (
    <AdminPrivateLayout>
      <InviteAgentWrapper>
        <HeadingWrapper>
          <MainHeading>
            <img src={PrevIcon} alt="agent-image" onClick={() => history.back()} />
            {getComponentTitleByStep(currentStep)}
          </MainHeading>
          <SubHeading>{getComponentInfoByStep(currentStep)}</SubHeading>
        </HeadingWrapper>
        <StepsWrapper className="stepper">
          <StepsContainer>
            <Steps progressDot current={currentStep} direction="horizontal" items={items}></Steps>
          </StepsContainer>
        </StepsWrapper>
        <AgentInfoWrapper>
          {/* <ButtonContainer>
          <Button label="Back" variant="outline" onClick={handleBack}>
            <Arrow />
          </Button>
        </ButtonContainer> */}
          <div>{getComponentByStep(currentStep)}</div>
        </AgentInfoWrapper>
      </InviteAgentWrapper>
    </AdminPrivateLayout>
  )
}

export default InviteAgent
