enum APIS {
  CREATE_PASSWORD = '/v1/auth/createPassword',
  FORGOT_PASSWORD = '/v1/auth/forgotPassword',
  LOG_IN = '/v1/auth/login',
  DRIVER_LOGIN = '/v1/auth/checkContact',
  DRIVER_LOGIN_OTP = '/v1/auth/loginviaMobile',
  GOOGLE_LOG_IN = '/v1/auth/googleLogin',
  INVITE_AGENT = '/v1/users/invite/agent',
  UPLOAD_FILE = '/v1/agent/uploadFile',
  INVITE_ADMIN = '/v1/users/invite/admin',
  GET_PROFILE_INFO = '/v1/users/getProfile',
  RESET_PASSWORD = '/v1/auth/resetPassword',
  USERS_DASHBOARD = '/v1/users/getDashboard',
  USERS_LIST = '/v1/users/list',
  MMI_TOKEN = '/v1/auth/mmi/token',
  ADMINS_LIST = '/v1/admins/list',
  DRIVERS_LIST = '/v1/drivers/list',
  UPDATE_PRICE_CALCULATION = '/v1/pricing/update',
  GET_PRICE_CALCULATION = '/v1/pricing',
  CHANGE_PASSWORD = '/v1/auth/changePassword',
  AGENT_LOCATION = '/v1/agent/location',
  AGENT_SSE = '/v1/agent/sse',
  ASSIGNE_AGENT = '/v1/task/assigneAgent',
  ALL_TASK = '/v1/task/get',
  SINGLE_TASK_ISSUES = 'v1/task/issue',
  AGENT_SEARCH = '/v1/agent/search',
  ALL_AGENT_TASK = '/v1/agent/getTasks',
  TRACK_TASK = '/v1/taskStatus/get',
  UPDATE_PROFILE = '/v1/users/updateProfile',
  CREATE_TASK_STATUS = '/v1/taskStatus/create',
  CANCEL_TASK_STATUS = '/v1/task/cancel',
  GET_NOTIFICATIONS = '/v1/task/notification',
  DELETE_DRIVER = '/v1/agent/delete',
  DEACTIVATE_DRIVER = '/v1/agent/block',
  UPDATE_SUPPORT = '/v1/applicationSetting/create',
  AGENT_TOGGLE_STATUS = '/v1/agent/toggleStatus',
  ISSUE_LIST = '/v1/issue/list',
  GET_ISSUE = '/v1/issue',
  GET_DRIVER_DETAIL = '/v1/agent/get',
  UPDATE_ISSUE = '/v1/issue/update',
  PROCESS_ISSUE = '/v1/issue/updateStatus',
  UPDATE_DRIVER = '/v1/agent',
  ADMIN_STATUS = '/v1/admins/alter',
  DRIVER_HISTORY = '/v1/agent/taskHistory',
  LOGOUT = '/v1/auth/logout',
  AGENT_LIVE_LOCATION = '/v1/agent/liveLocation',
  HUBS_LIST = '/v1/hub/hubList',
  GET_HUB_DETAIL = '/v1/hub',
  UPDATE_HUB = '/v1/hub/update',
  CREATE_HUB = '/v1/hub/create',
  DELETE_HUB = '/v1/hub/delete',
  UPDATE_AGENT_TOGGLE_STATUS = '/v1/agent/updateAgentStatus',
  REQUEST_LOGS = '/v1/ondcRequest',
}
export default APIS
