import styled from 'styled-components'
import { theme } from 'styles/theme'
import { AddDetailsWrapper } from 'styles/views/inviteAgentScreen/driverDetailsSection'

export const SuccessfulModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 90px;
  width: 672px;
  padding-bottom: 80px;
  z-index: 9999;
  button {
    width: 190px;
  }
  @media (max-width: 767px) {
    width: 500px;
    gap: 10px;
  }
  @media (max-width: 514px) {
    width: 400px;
    gap: 10px;
    padding-bottom: 20px;
  }
`
export const ModalContainer = styled(SuccessfulModalContainer)`
  padding-bottom: 0px;
  gap: 0;
  border-top: unset;
  z-index: 0;

  @media (max-width: 767px) {
    width: 92vw;
    gap: 10px;
    z-index: 0;
  }

  &.model-container {
    width: 750px;
    padding: 20px;
    @media (max-width: 767px) {
      width: 100%;
      gap: 10px;
      z-index: 0;
      padding: 10px;
    }
  }
`
export const DeleteModalContainer = styled(SuccessfulModalContainer)`
  gap: 0;
  width: 440px;
  padding: 20px;
  @media (max-width: 514px) {
    width: 85vw;
    gap: 10px;
    padding-bottom: 20px;
  }
  &.add-hub {
    @media (max-width: 767px) {
      width: unset;
    }
  }
`
export const Label = styled.div`
  color: ${theme.PRIMARYBLACKCOLOR};
  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: start;
  li {
    list-style: none;
  }
`
export const CloseButton = styled.div`
  width: 100%;
  padding: 30px 0px 30px 0;
  text-align: end;
  svg {
    cursor: pointer;
    margin-top: 10px;
  }
  @media (max-width: 514px) {
    padding: 20px 0px 20px 0;

    svg {
      margin-right: 10px;
    }
  }
`
export const CloseBtn = styled(CloseButton)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 0px;
  margin-bottom: 50px;
  &.add-hub {
    @media (max-width: 767px) {
      justify-content: center;
      align-items: baseline;
      gap: 40px;
    }
  }
  &.desktop-add-hub {
    @media (min-width: 767px) {
      justify-content: space-between;
    }
  }
`
export const CloseButtonWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.PRIMARYBLUECOLOR200};
  padding: 30px 0px 30px 0;
  text-align: end;
  svg {
    cursor: pointer;
    margin-right: 30px;
  }
  @media (max-width: 514px) {
    padding: 20px 0px 20px 0;

    svg {
      margin-right: 10px;
    }
  }
`
export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 6px;
  @media (max-width: 514px) {
    svg {
      width: 80px;
      height: 80px;
    }
  }

  @media (max-width: 768px) {
    align-items: start;
  }

  &.desktop-add-hub {
    @media (min-width: 767px) {
      align-items: start;
    }
  }
`
export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 150px;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 10px;
  }
`
export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 100%;
  max-width: 624px;
  border-top: 1px solid ${theme.PRIMARYBLUECOLOR200};
  padding: 24px;
`
export const AssignFormContainer = styled.form`
  width: 100%;
`
export const AssignedFormWrapper = styled.div`
  max-height: 500px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 100%;
  max-width: 624px;
  padding: 24px;
  @media (max-width: 1400px) {
    max-height: 314px;

    padding: 14px;
    gap: 10px;
  }

  @media (max-width: 767px) {
    width: 90%;
  }
`
export const DriverFormContainer = styled(FormContainer)`
  border-top: unset;
  gap: 8px;
  padding: 0;
  max-width: 1000px;
`
export const OrderFormContainer = styled(FormContainer)`
  gap: 10px;
`
export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 30px;
  @media (max-width: 514px) {
    max-width: 300px;
  }
`

export const LogoutIconWrap = styled.div`
  svg {
    width: 60px;
    height: 60px;
    path {
      stroke: ${theme.PRIMARYCOLOR};
      stroke-width: 2.711;
    }
  }
`
export const AddContentContainer = styled(ContentContainer)`
  gap: 10px;
  @media (max-width: 767px) {
    max-width: 100%;
  }
  &.add-hub {
    @media (max-width: 767px) {
      max-width: 90%;
    }
  }
`
export const DocumentContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 624px;
  padding: 24px 24px 24px 24px;
  &.heading-container {
    border-bottom: none;
    h3 {
      font-size: 28px;
      font-weight: 400;
      line-height: 34.3px;
      letter-spacing: -0.022em;
      text-align: left;
    }
  }
  svg {
    cursor: pointer;
  }
  @media (max-width: 767px) {
    max-width: 372px;
    padding-bottom: 0px;
    width: 90%;
  }

  &.add-hub {
    @media (max-width: 767px) {
      width: 100%;
      max-width: unset;
      padding: 10px 24px 0px 24px;
    }
  }

  &.admin-heading {
    padding: unset;
    justify-content: space-between;
    max-width: 750px;
    @media (max-width: 767px) {
      max-width: 365px;
      margin-left: -25px;
    }
  }
`
export const DocumentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 650px;
  overflow-y: auto;
  @media (max-width: 1280px) {
    height: 560px;
  }
  @media (min-height: 570px) {
    height: 560px;
  }
  img {
    width: 100%;
  }
  .page {
    width: 600px;
  }
  @media (max-width: 1280px) {
    height: 260px;
  }
`
export const DocumentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const DocumentInnerWrapper = styled.div`
  height: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  @media (max-width: 1280px) {
    height: 390px;
  }
`
export const ImageWrapper = styled.img``
export const HeadingWrapper = styled.h3`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: ${theme.PRIMARYBLACKCOLOR};
  @media (max-width: 514px) {
    font-size: 18px;
    line-height: 24px;
  }
`
export const Description = styled.p`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${theme.BLACKCOLOR};
  @media (max-width: 514px) {
    font-size: 12px;
    line-height: 18px;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    width: 187px;
  }
  .cancel {
    background-color: ${theme.NEUTRALGREYCOLOR};
    color: ${theme.PRIMARYBLACKCOLOR};
  }
`
export const Resend = styled.div`
  font-family: 'Inter';
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.BOXCOLOR};
`
export const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 635px;
  display: flex;
  gap: 20px;
  justify-content: end;

  padding: 20px;

  .cancel {
    background-color: #fff;
    color: ${theme.PRIMARYCOLOR};
    border: 1px solid ${theme.PRIMARYCOLOR};
  }
  .cancel-now {
    background-color: ${theme.ERROR};
  }
  @media (max-width: 767px) {
    max-width: 355px;
    width: 90%;
  }
  &.button-wrapper {
    // justify-content: center;
    button:nth-of-type(1) {
      background: #fff;
      border: 1px solid rgba(48, 145, 208, 0.8);
      color: rgba(48, 145, 208, 0.8);
    }
    button {
      max-width: 150px;
      height: 30px;
      background: rgba(48, 145, 208, 0.8);
    }
  }

  &.driver-button-wrapper {
    // justify-content: center;
    button:nth-of-type(1) {
      background: #1818181a;
      border: none;
      color: ${theme.BLACKCOLOR};
      height: 40px;
    }
    button {
      max-width: 170px;
      height: 30px;
      background: ${theme.SECONDARYBLUECOLOR};
      height: 40px;
    }
  }
  &.admin-button {
    max-width: 750px;
    padding-top: 0px;
    padding-left: 0;
    @media (max-width: 767px) {
      max-width: 365px;
    }
  }

  &.add-hub {
    @media (max-width: 767px) {
      padding: 20px 0;
    }
  }

  &.update-hub {
    @media (max-width: 767px) {
      width: 100%;
      max-width: unset;
      padding: 20px 0;
      button {
        width: 100%;
        max-width: unset;
      }
    }
  }
`
export const ActionButtonWrapper = styled.div`
  width: 100%;
  max-width: 635px;
  display: flex;
  gap: 20px;
  justify-content: end;
  border-top: 1px solid ${theme.PRIMARYBLUECOLOR200};
  padding: 20px;
  button {
    width: 120px;
  }
  .cancel {
    background-color: ${theme.NEUTRALGREYCOLOR};
    color: ${theme.PRIMARYBLACKCOLOR};
  }
  .cancel-now {
    background-color: ${theme.ERROR};
  }
  @media (max-width: 767px) {
    max-width: 365px;
  }
`
export const ButtonWrap = styled(ButtonWrapper)`
  border-top: none;
  padding: 0;
  justify-content: center;

  button {
    width: 100%;
    height: 40px;
    background: #3091d0;
  }
  button:nth-of-type(1) {
    border: 1px solid #3091d0;
    color: #3091d0;
  }
`
export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`
export const AddFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 624px;
  padding: 24px;
  &.form-container {
    padding: 0 14px;
    div {
      div {
        min-height: unset;
      }
    }
  }
  @media (max-width: 767px) {
    max-width: 365px;
  }
  &.admin-form {
    max-width: 750px;
    padding: 20px 0px 0px 0px;
    @media (max-width: 767px) {
      max-width: 365px;
    }
  }
`
export const ActionFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 25px; */
  width: 100%;
  max-width: 624px;
  padding: 24px;
  @media (max-width: 767px) {
    max-width: 365px;
  }
`
export const RadioWrapper = styled.div`
  margin-bottom: 25px;
`
export const OptionWrapper = styled.div`
  display: flex;
  gap: 29px;
  width: 100%;
  max-width: 624px;
  @media (max-width: 767px) {
    max-width: 365px;
  }
  .ant-radio-wrapper .ant-radio-inner {
    width: 20px;
    height: 20px;
  }
`
export const EditPinWrapper = styled.div`
  input {
    width: 200px;
    height: 30px;
  }
`
export const Options = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const CancelFormContainer = styled(AddFormContainer)``
export const UpdateDetailContainer = styled(AddDetailsWrapper)`
  max-height: 65vh;
  text-align: start;
  width: 624px;
  padding: 0 24px;
  gap: 0;
`
export const UpdateDriverDetailContainer = styled(UpdateDetailContainer)`
  overflow: auto;
  ::-webkit-scrollbar {
      display: none;
    }
  &.add-hub {
    @media (max-width: 767px) {
      padding: 0;
      width: unset;
    }
  }
`
export const DriverDetailsContainer = styled(AddDetailsWrapper)`
  max-height: 51vh;
  width: 623px;
  gap: 0;
  .detail-form {
    width: 623px;
    gap: 0;
  }
  .form-title {
    text-align: start;
  }
  .driver-details {
    max-height: 40vh;
  }
  .button-container {
    padding: 20px 0;
  }
  .toggle-button {
    .ant-switch.ant-switch-checked {
      width: 40px;
      background: ${theme.SECONDARYBLUECOLOR};
    }
    .ant-switch {
      width: 40px;
    }
  }
  @media (min-width: 1440px) and (max-width: 2560px) {
    width: 100%;
  }
`
