import PrevIcon from 'assets/svg/PrevIcon'
import NextIcon from 'assets/svg/NextIcon'

interface CustomPaginationProps {
  currentPage: number
  totalPages: number
  onPrev: () => void
  onNext: () => void
  pageSize: number
}

const CustomPagination = ({ currentPage, totalPages, onPrev, onNext, pageSize }: CustomPaginationProps) => {
  const isPrevDisabled = currentPage === 0
  const isNextDisabled = currentPage === (totalPages - 1) * pageSize

  const disabledStyle = {
    cursor: 'not-allowed',
    opacity: 0.5,
  }

  return (
    <div className="custom-pagination">
      <button
        className="prev-button"
        onClick={onPrev}
        disabled={isPrevDisabled}
        style={isPrevDisabled ? disabledStyle : {}}
      >
        <PrevIcon />
      </button>
      <span>
        Page {currentPage / pageSize + 1} of {totalPages}
      </span>
      <button
        className="next-button"
        onClick={onNext}
        disabled={isNextDisabled}
        style={isNextDisabled ? disabledStyle : {}}
      >
        <NextIcon />
      </button>
    </div>
  )
}

export default CustomPagination
