import { useEffect, useState } from 'react'
// import { Breadcrumb } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { IParamId } from 'interfaces/pages'
import useGet from 'hooks/useGet'
import APIS from 'constants/api'
import Modal from 'components/Modal'
import AdminPrivateLayout from 'components/Layouts/adminPrivateLayout'
import UpdateStatus from 'views/updateModal'
import OrderDetail from 'views/adminDashboard/gpsTracker'
import {
  MainWrapper,
  HeadingWrapper,
  // BreadcrumbWrapper,
  MainHeading,
  DetailContainer,
  DetailSection,
} from 'styles/pages/gpsTracker'
import PrevIcon from 'assets/images/arrow-left.png'
import Button from 'components/Button'
import { AdminButtonWrapper } from 'styles/views/dashboard'

const GPSTracker = () => {
  const history = useHistory()
  const { id }: IParamId = useParams()
  const { refetch: getLocationTrack, data: trackLocation } = useGet('track-location', `${APIS.ALL_TASK}/${id}`)

  useEffect(() => {
    getLocationTrack()
  }, [getLocationTrack])

  // const TaskBreadcrumb: React.FC = () => (
  //   <Breadcrumb
  //     separator=">"
  //     items={[
  //       {
  //         title: 'All Orders',
  //         href: '/tasks',
  //       },
  //       {
  //         title: 'Order Details',
  //       },
  //     ]}
  //   />
  // )
  const [updateModal, setUpdateModal] = useState(false)

  return (
    <AdminPrivateLayout>
      <MainWrapper>
        <HeadingWrapper>
          {/* <BreadcrumbWrapper>
            <TaskBreadcrumb />
          </BreadcrumbWrapper> */}

          <MainHeading>
            <img src={PrevIcon} alt="back-button" onClick={() => history.goBack()} />
            Order Details
          </MainHeading>

          <AdminButtonWrapper>
            <Button
              type="button"
              label="See Logs"
              variant="outline"
              onClick={() => history.push(`/request-log/${trackLocation.data?.task?.transaction_id}`)}
            />
          </AdminButtonWrapper>
        </HeadingWrapper>
        <DetailContainer>
          <DetailSection>
            <OrderDetail details={trackLocation} />
          </DetailSection>
        </DetailContainer>
      </MainWrapper>
      <Modal isOpen={updateModal}>
        <UpdateStatus showModal={(value: boolean) => setUpdateModal(value)} />
      </Modal>
    </AdminPrivateLayout>
  )
}

export default GPSTracker
