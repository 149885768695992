import styled from 'styled-components'
import { theme } from 'styles/theme'
import { MapLocationImage } from 'styles/views/driverFlowHome'

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 30px;
  gap: 16px;

  @media (max-width: 768px) {
    padding: 5px 12px;
  }
`
export const IssuesMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 30px;
  gap: 16px;
  @media (max-width: 768px) {
    padding: 5px 12px;
  }
`
export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
export const BreadcrumbWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`
export const MainHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: ${theme.PRIMARYBLACKCOLOR};
  img {
    cursor: pointer;
  }
  @media (max-width: 768px) {
    font-size: 20px;
    align-items: center;
  }
`
export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 29px;
  padding: 30px 40px;
  border-radius: 16px;
  background: ${theme.WHITE};
  /* max-height: 69vh; */
  overflow-y: scroll;
  border: 1px solid ${theme.GREYBORDER};
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    padding: 5px 20px;
  }
`
export const DetailSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 29px;
`
export const MapWrapper = styled(MapLocationImage)`
  height: 600px;
  #map {
    height: 600px;
    max-width: 100%;
    /* max-width: 600px; */
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  button {
    width: 187px;
  }
  .cancel-button {
    background: ${theme.ERROR};
  }
`

export const IssueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  button {
    width: 150px;
    @media (max-width: 768px) {
      height: 37px;
      width: 105px;
    }
  }

  @media (max-width: 768px) {
    button {
      width: 110px;
      height: 32x;
    }
  }
`
