/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { Popover, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import NoRecords from 'components/RecordNotFound'
import Modal from 'components/Modal'
import { IAdminData } from 'interfaces'
import AdminStatusModal from 'views/adminStatusModal'
import OptionIcon from 'assets/svg/OptionIcon'
import {
  NumberWrapper,
  AdminStatusWrapper,
  AdminImageWrapper,
  AdminStatusTextWrapper,
} from 'styles/views/adminDashboard/tableDescription'
import { ContentWrapper, StyledTableWrapper, Title } from 'styles/components/Navbar'
import PrevIcon from 'assets/svg/PrevIcon'
import NextIcon from 'assets/svg/NextIcon'
import CustomPagination from '../tasksTable/taskDescriptionTable/customPagination'
import { useMediaQuery } from 'hooks/useMobile'

const AdminData = ({
  fetchAdmin,
  users,
  currentPage,
  pageSize,
  setCurrentPage,
  setPageSize,
  totalCount,
  searchedText,
}: IAdminData) => {
  const [showPagination, setShowPagination] = useState(true)
  const [adminStatusModal, setAdminStatusModal] = useState(false)
  const [adminId, setAdminId] = useState<string>('')
  const [value, setValue] = useState<number>(0)
  const [popoverOpen, setPopoverOpen] = useState<any[]>([])

  const handleActionClick = (index: number) => {
    setPopoverOpen((prevOpen) => {
      const updatedOpen = [...prevOpen]
      updatedOpen[index] = false
      return updatedOpen
    })
  }

  const handlePopoverOpenChange = (index: number, open: any) => {
    setPopoverOpen((prevOpen) => {
      const updatedOpen = [...prevOpen]
      updatedOpen[index] = open
      return updatedOpen
    })
  }

  const actionsContent = (item: { enabled: number; _id: string }, index: any) => {
    return (
      <ContentWrapper onClick={() => handleActionClick(index)}>
        {item?.enabled == 0 ? (
          <Title onClick={() => deactivateDriver(item?._id, 1)}>Active</Title>
        ) : (
          <Title onClick={() => deactivateDriver(item?._id, 0)}>Inactive</Title>
        )}
      </ContentWrapper>
    )
  }
  const deactivateDriver = (id: string, value: number) => {
    setPopoverOpen((prevOpen) => prevOpen.map(() => false))
    setAdminId(id)
    setAdminStatusModal(true)
    setValue(value)
  }

  useEffect(() => {
    if (searchedText === '' || searchedText === undefined) {
      setShowPagination(true)
    } else {
      setShowPagination(true)
    }
  }, [searchedText])

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      render: (data: string | JSX.Element) => {
        if (typeof data === 'string') {
          return <NumberWrapper>{data}</NumberWrapper>
        }

        return data
      },
      filteredValue: searchedText ? [searchedText] : null,
      onFilter: (value: any, record: any) => {
        return (
          String(record?.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.mobile).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.email).toLowerCase().includes(value.toLowerCase())
        )
      },
    },
    {
      title: 'Mobile Number ',
      dataIndex: 'mobile',
      key: 'mobile',
      width: 150,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 150,
    },
    {
      title: 'Status',
      dataIndex: 'enabled',
      key: 'status',
      width: 100,
      render: (data) => {
        return (
          <AdminStatusWrapper status={data}>
            <AdminImageWrapper status={data} />
            <AdminStatusTextWrapper status={data}>{data === 1 ? 'Active' : 'Inactive'}</AdminStatusTextWrapper>
          </AdminStatusWrapper>
        )
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: 100,
      render: (item, id, index) => {
        return (
          <Popover
            placement="bottomLeft"
            content={actionsContent(id, item)}
            open={popoverOpen[index]}
            onOpenChange={(open) => handlePopoverOpenChange(index, open)}
            trigger="hover"
          >
            <OptionIcon />
          </Popover>
        )
      },
    },
  ]
  const totalPages = Math.ceil(totalCount / pageSize)
  const itemRender = (current: any, type: string, originalElement: any) => {
    const isPrevDisabled = current === 0
    const isNextDisabled = current === totalPages

    const disabledStyle = {
      cursor: 'not-allowed',
      opacity: 0.5,
    }
    if (type === 'prev') {
      return (
        <button className="prev-button" disabled={isPrevDisabled} style={isPrevDisabled ? disabledStyle : {}}>
          <div>
            <PrevIcon /> Previous
          </div>
        </button>
      )
    }

    if (type === 'next') {
      return (
        <button className="next-button" disabled={isNextDisabled} style={isNextDisabled ? disabledStyle : {}}>
          <div>
            Next
            <NextIcon />
          </div>
        </button>
      )
    }

    return originalElement
  }

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - pageSize)
    }
  }

  const handleNext = () => {
    if (currentPage < (totalPages - 1) * pageSize) {
      setCurrentPage(currentPage + pageSize)
    }
  }
  const isMobile = useMediaQuery('(max-width: 768px)')

  return (
    <>
      <StyledTableWrapper id="styled-table-wrapper">
        {!isMobile ? (
          <Table
            scroll={{ y: 250 }}
            columns={columns}
            locale={{ emptyText: <NoRecords /> }}
            dataSource={users}
            pagination={
              showPagination &&
              totalCount > 5 && {
                pageSizeOptions: ['5', '10', '20'],
                showSizeChanger: true,
                locale: { items_per_page: 'Records Per Page' },
                current: Math.ceil(currentPage / pageSize) + 1,
                pageSize: pageSize,
                total: totalCount,
                onChange: (currentPage, pageSize) => {
                  const newSkipValue = (currentPage - 1) * pageSize
                  setCurrentPage(newSkipValue)
                  setPageSize(pageSize)
                },
                itemRender: itemRender,
              }
            }
            rowKey="email"
          />
        ) : (
          <>
            <Table
              scroll={{ y: 560 }}
              columns={columns}
              locale={{ emptyText: <NoRecords /> }}
              dataSource={users}
              pagination={false}
              rowKey="task_id"
            />
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPrev={handlePrev}
              onNext={handleNext}
              pageSize={pageSize}
            />
          </>
        )}
      </StyledTableWrapper>
      <Modal isOpen={adminStatusModal}>
        <AdminStatusModal
          showModal={(value: boolean) => setAdminStatusModal(value)}
          id={adminId}
          subTitle={value === 1 ? 'Activate Admin' : 'Inactivate Admin'}
          title={value === 1 ? 'Active Admin' : 'Inactive Admin'}
          value={value}
          fetchAdmin={fetchAdmin}
        />
      </Modal>
    </>
  )
}

export default AdminData
