import styled from 'styled-components'
import { theme } from 'styles/theme'

export const DriverProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e9eb;
  border-radius: 6px;
  padding: 30px;
  background-color: ${theme.WHITE};
  max-height: 69vh;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 767px) {
    padding: 20px;
    max-height: 78vh;
  }
`
export const DriverDetailsWrapper = styled.div`
  display: flex;
  gap: 40px;
  border-bottom: 1px solid #00000014;
  padding-bottom: 20px;

  &.hub-detail {
    @media (max-width: 768px) {
      padding: 0;
    }
  }

  &.driver-detail {
    border-bottom: none;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`
export const DriverDetails = styled.div`
  display: flex;
  gap: 16px;
  img {
    height: 40px;
  }
  &.driver-details {
    padding-left: 25px;
    width: 35%;
  }

  @media (max-width: 768px) {
    &.driver-details {
      padding-left: 0px;
      width: 100%;
    }
  }
`
export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`
export const Title = styled.div`
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.TITLECOLOR};
`
export const Detail = styled.div`
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.PRIMARYBLACKCOLOR};

  &.detail {
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.006em;
    text-align: left;
  }
`
export const DriverTaskWrapper = styled.div`
  display: flex;
  gap: 50px;
  @media (max-width: 768px) {
    padding: 0px;
    gap: 20px;
    text-align: center;
  }
`
export const TaskDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    align-items: center;
  }
`
export const TaskDetail = styled.div`
  font-family: 'Inter';
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${theme.PRIMARYBLACKCOLOR};
  text-align: center;
`
export const TaskTitle = styled.div`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.TITLECOLOR};
`
export const DriverInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 0 20px;

  &.info-wrapper {
    padding-left: 25px;

    @media (max-width: 768px) {
      padding-left: 0px;
    }
  }
`
export const Heading = styled.div`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${theme.PRIMARYBLACKCOLOR};
`
export const DriverInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 253px);
  grid-column-gap: 200px;
  grid-row-gap: 30px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 170px);
    grid-column-gap: 50px;
  }
  &.hub-detail {
    @media (max-width: 768px) {
      grid-row-gap: 10px;
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
export const HubInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 17px;

  .addPin {
    margin-top: 24px;
    width: 120px;
    height: 44px;
    /* :hover {
      background: ${theme.PRIMARYCOLOR};
      color: ${theme.WHITE};
    } */
  }
  &.add-hub {
    @media (max-width: 767px) {
      grid-template-columns: auto;
    }
  }
`
export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const InfoTitle = styled.div`
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.TITLECOLOR};
`
export const HubInfoTitle = styled.div`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${theme.TITLECOLOR};
`
export const DriverInfo = styled.div`
  display: flex;
  gap: 5px;
`
export const InfoDetails = styled.div`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.PRIMARYBLACKCOLOR};
  text-transform: capitalize;
  li {
    list-style: none;
    padding: 0;
  }
`
export const HubInfoDetails = styled.div`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${theme.PRIMARYBLACKCOLOR};
  text-transform: capitalize;
  li {
    list-style: none;
    padding: 0 0 7px 0;
  }
`
export const DriverInfoDetails = styled(InfoDetails)`
  text-transform: unset;
`
export const InfoDetailsLink = styled.a`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: rgba(48, 145, 208, 0.8);
  cursor: pointer;
`
export const MaskPanCard = styled.div`
  display: flex;
  gap: 4px;
  svg {
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 0;
  gap: 10px;
  button {
    max-width: 187px;
  }
  .cancel {
    background-color: ${theme.NEUTRALGREYCOLOR};
    color: ${theme.PRIMARYBLACKCOLOR};
  }
  &.button-wrapper {
    justify-content: center;
    button {
      max-width: 150px;
      height: 30px;
      background: rgba(48, 145, 208, 0.8);
    }
  }
`
