import { SVGProps } from 'react'

const HomeIconDesktop = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_66_3370)">
      <path d="M13 20H11L20 11L29 20H27" stroke="" strokeWidth={1.25} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M13 20V27C13 27.5304 13.2107 28.0391 13.5858 28.4142C13.9609 28.7893 14.4696 29 15 29H25C25.5304 29 26.0391 28.7893 26.4142 28.4142C26.7893 28.0391 27 27.5304 27 27V20"
        stroke=""
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 29V23C17 22.4696 17.2107 21.9609 17.5858 21.5858C17.9609 21.2107 18.4696 21 19 21H21C21.5304 21 22.0391 21.2107 22.4142 21.5858C22.7893 21.9609 23 22.4696 23 23V29"
        stroke=""
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_66_3370">
        <rect width={24} height={24} fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
)
export default HomeIconDesktop
