const SvgComponent = () => (
  <svg width="32" height="22" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 15H27C27.2652 15 27.5196 15.1054 27.7071 15.2929C27.8946 15.4804 28 15.7348 28 16C28 16.2652 27.8946 16.5196 27.7071 16.7071C27.5196 16.8946 27.2652 17 27 17H7C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z"
      fill="black"
    />
    <path
      d="M7.41383 16L15.7078 24.292C15.8956 24.4798 16.0011 24.7344 16.0011 25C16.0011 25.2655 15.8956 25.5202 15.7078 25.708C15.5201 25.8958 15.2654 26.0012 14.9998 26.0012C14.7343 26.0012 14.4796 25.8958 14.2918 25.708L5.29183 16.708C5.19871 16.6151 5.12482 16.5047 5.07441 16.3832C5.024 16.2618 4.99805 16.1315 4.99805 16C4.99805 15.8684 5.024 15.7382 5.07441 15.6167C5.12482 15.4952 5.19871 15.3849 5.29183 15.292L14.2918 6.29198C14.4796 6.10421 14.7343 5.99872 14.9998 5.99872C15.2654 5.99872 15.5201 6.10421 15.7078 6.29198C15.8956 6.47975 16.0011 6.73443 16.0011 6.99998C16.0011 7.26553 15.8956 7.52021 15.7078 7.70798L7.41383 16Z"
      fill="black"
    />
  </svg>
)
export default SvgComponent
