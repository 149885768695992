/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
// import { Breadcrumb } from 'antd'
import Modal from 'components/Modal'
import AdminPrivateLayout from 'components/Layouts/adminPrivateLayout'
import HubsProfileDetails from 'views/hubsProfileDetail'
import {
  IssuesMainWrapper,
  HeadingWrapper,
  //  BreadcrumbWrapper,
  MainHeading,
} from 'styles/pages/gpsTracker'
import { AdminButtonWrapper, ImageWrapper } from 'styles/views/dashboard'
import PrevIcon from 'assets/images/arrow-left.png'
import { useMediaQuery } from 'hooks/useMobile'
import Button from 'components/Button'
import PlusIcon from 'assets/svg/PlusIcon'
import { useEffect, useState } from 'react'
import AddHubsModal from 'views/addHubsModal'
import useGet from 'hooks/useGet'
import APIS from 'constants/api'

const HubDetail = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [searchedText, setSearchedText] = useState('')
  const { refetch: getHubs, data: hubsDetails } = useGet(
    'get-hub',
    `${APIS.HUBS_LIST}?skip=${currentPage}&search=${searchedText}&limit=${pageSize}`,
  )
  useEffect(() => {
    getHubs()
  }, [pageSize, currentPage, searchedText])

  const [adminModal, setAdminModal] = useState(false)
  const isMobile = useMediaQuery('(max-width: 768px)')
  const addAdmin = () => {
    setAdminModal(true)
  }

  return (
    <AdminPrivateLayout>
      <IssuesMainWrapper>
        <HeadingWrapper>
          {/* <BreadcrumbWrapper>
            <HubBreadcrumb />
          </BreadcrumbWrapper> */}
          <MainHeading>
            <ImageWrapper style={{ maxWidth: 'unset' }}>
              <img src={PrevIcon} alt="agent-image" onClick={() => history.back()} />
            </ImageWrapper>
            Hub Details
          </MainHeading>
          {isMobile && (
            <AdminButtonWrapper className="hub-detail-button">
              <Button type="submit" label={'Add Hubs'} variant="outline" onClick={addAdmin}>
                <PlusIcon />
              </Button>
            </AdminButtonWrapper>
          )}
        </HeadingWrapper>
        <HubsProfileDetails />
      </IssuesMainWrapper>
      <Modal isOpen={adminModal}>
        <AddHubsModal showModal={(value: boolean) => setAdminModal(value)} getHubs={getHubs} />
      </Modal>
    </AdminPrivateLayout>
  )
}

export default HubDetail
