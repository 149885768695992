import ROLES from './role'
import HomeIcon from 'assets/svg/HomeIcon'
import HomeIconDesktop from 'assets/svg/DesktopHomeIcon'
import HistoryIcon from 'assets/svg/HistoryIcon'
import ProfileIcon from 'assets/svg/ProfileIcon'
import GroupIcon from 'assets/svg/GroupIcon'
import OrderIcon from 'assets/svg/OrdersIcon'
import IssueIcon from 'assets/svg/IssueIcon'
import HubsIcon from 'assets/svg/HubsIcon'
import RequestLogIcon from 'assets/svg/RequestLogIcon'

const MENU = {
  [ROLES.SUPER_ADMIN]: [
    {
      title: 'Dashboard',
      link: '/dashboard',
    },

    {
      title: 'Agents ',
      link: '/agents',
    },
    {
      title: 'Orders',
      link: '/orders',
    },

    {
      title: 'Issues',
      link: '/complaints',
    },
    {
      title: 'Hubs',
      link: '/hubs',
    },
    {
      title: 'Request Logs',
      link: '/request-log/new',
    },
  ],
  [ROLES.ADMIN]: [
    {
      title: 'Dashboard',
      link: '/dashboard',
      icon: <HomeIconDesktop />,
    },

    {
      title: 'Driver & Admin',
      link: '/driver',
      icon: <GroupIcon />,
    },
    {
      title: 'Orders',
      link: '/tasks',
      icon: <OrderIcon />,
    },

    {
      title: 'Issues',
      link: '/complaints',
      icon: <IssueIcon />,
    },
    {
      title: 'Hubs',
      link: '/hubs',
      icon: <HubsIcon />,
    },
    {
      title: 'logs',
      link: '/request-log',
      icon: <RequestLogIcon />,
    },
    // {
    //   title: 'Request Logs',
    //   link: '/request-log/new',
    //   icon: <HomeIcon />,

    // },
  ],
  [ROLES.DRIVER]: [
    {
      title: 'Home',
      link: '/home',
      icon: <HomeIcon />,
    },
    {
      title: 'History',
      link: '/history',
      icon: <HistoryIcon />,
    },
    {
      title: 'Profile',
      link: '/profile',
      icon: <ProfileIcon />,
    },
  ],
}
export default MENU
