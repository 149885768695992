import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_53_3404)">
      <path d="M3 21H21" stroke="#3091D0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 8H10" stroke="#3091D0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 12H10" stroke="#3091D0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 16H10" stroke="#3091D0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 8H15" stroke="#3091D0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 12H15" stroke="#3091D0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 16H15" stroke="#3091D0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21"
        stroke="#3091D0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_53_3404">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
