import { SVGProps } from 'react'

const IssueIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity={0.4} clipPath="url(#clip0_66_3393)">
      <path
        d="M22 11V15C22 15.2652 22.1054 15.5196 22.2929 15.7071C22.4804 15.8946 22.7348 16 23 16H27"
        stroke=""
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 29H15C14.4696 29 13.9609 28.7893 13.5858 28.4142C13.2107 28.0391 13 27.5304 13 27V13C13 12.4696 13.2107 11.9609 13.5858 11.5858C13.9609 11.2107 14.4696 11 15 11H22L27 16V27C27 27.5304 26.7893 28.0391 26.4142 28.4142C26.0391 28.7893 25.5304 29 25 29Z"
        stroke=""
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M20 25H20.01" stroke="" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 19V22" stroke="" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_66_3393">
        <rect width={24} height={24} fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
)

export default IssueIcon
