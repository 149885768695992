import styled from 'styled-components'
import { theme } from 'styles/theme'

interface IProps {
  status?: string | boolean
  activeIndex?: string
  variant?: 'contained' | 'Escalate' | 'Resolved' | 'disabled' | 'inProgress' | 'Pending' | 'Active' | 'Inactive'
}

export const TrackingDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  height: 100vh;
  padding: 0 30px;
  li {
    span {
      cursor: pointer;
    }
  }
  @media (max-width: 768px) {
    padding: 0px 20px 10px 20px;
  }
`
export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  /* > div {
    width: 15%;
  } */
`
export const MainHeading = styled.h3`
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: ${theme.PRIMARYBLACKCOLOR};
  img {
    cursor: pointer;
  }
  @media (max-width: 768px) {
    font-size: 20px;
  }
`
export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  border: 1px solid #00000026;
  border-radius: 10px;
  padding: 20px 25px;
  height: 70vh;
  background-color: ${theme.WHITE};
  &.detail-wrapper {
    /* max-height: 69vh; */
    height: 100%;
    overflow-y: scroll;
    padding: 20px 10px;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`
export const OrderInformationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  @media (max-width: 1300px) {
    flex-direction: column;
    gap: 15px;
  }
`
export const OrderDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
export const DetailsInfo = styled.div`
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &.details-info {
    max-width: unset;
  }
`
export const TrackingID = styled.div`
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.PRIMARYBLACKCOLOR};
`
export const OrderPlacementDate = styled.div`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.TITLECOLOR};
`
export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
export const Title = styled.div`
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.TITLECOLOR};
`
export const OrderStatus = styled.div`
  width: max-content;
  border-radius: 21px;
  padding: 6px 10px;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  background: #419e6a38;
  color: #419e6a;
`
export const OrderWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid ${theme.GREYBORDER};
  padding: 5px;
  @media (max-width: 768px) {
    border: none;
  }

  /***** TABLE CSS******/

  .ant-table {
    border-radius: 0 0 10px 10px;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    background: ${theme.BLUECOLOR};
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${theme.PRIMARYBLACKCOLOR};
    ::before {
      background-color: none !important;
    }
  }
  .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-start-radius: 0;
  }
  .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
    border-start-end-radius: 0;
  }
  .ant-table-wrapper .ant-table {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${theme.GREYCOLOR};
  }
  &.tracking-details {
    .ant-steps {
      /* overflow-x: scroll; */
    }
  }
`
export const TitleWrapper = styled.div`
  background: #f5f5f5;
  border-radius: 10px 10px 0 0;
`
export const OrderStatusTitle = styled.div`
  font-family: 'Inter';
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: ${theme.PRIMARYBLACKCOLOR};
  padding: 26px 42px;
  span {
    color: ${theme.PRIMARYCOLOR};
  }
`
export const OrderStepperWrapper = styled.div`
  height: auto;
  display: flex;
  padding: 44px 74px;
  overflow: auto;

  /***** STEPPER CSS *****/
  .ant-steps.ant-steps-dot .ant-steps-item-content {
    max-width: 210px;
    min-width: 210px;
    width: 100%;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .ant-steps .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    max-width: 210px;
    width: 100%;
  }
`
export const OrderTrackingStepperWrapper = styled.div`
  height: auto;
  display: flex;
  padding: 44px 74px;
  overflow: auto;
  @media (max-width: 768px) {
    padding: 10px 10px;
  }

  /***** STEPPER CSS *****/
  .ant-steps.ant-steps-dot .ant-steps-item-content {
    max-width: 210px;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .ant-steps .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    min-width: 210px;
    max-width: 210px;
    width: 100%;
  }

  .ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: ${theme.TITLECOLOR};
  }
  .ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background: ${theme.TITLECOLOR};
  }
  .ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: ${theme.TITLECOLOR};
  }
`
export const LocationWrapper = styled.div`
  font-family: 'Inter';
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.PRIMARYBLACKCOLOR};
`
/****** LOCATION POPUP CSS ********/

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  min-height: 300px;
  border: 1px solid ${theme.NEUTRALGREYCOLOR};
  border-radius: 6px;
  padding: 10px;
  #map {
    height: 300px;
    > canvas {
      height: 300px;
    }
  }
`
export const Heading = styled.div`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${theme.PRIMARYCOLOR};
`
/****** ORDER STATUS MODAL CSS ********/

export const OrderStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  min-height: 330px;
  border: 1px solid ${theme.NEUTRALGREYCOLOR};
  border-radius: 6px;
  padding: 10px;
`
export const OrderHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 30vh;
`
export const SubTitle = styled.div`
  font-family: 'Inter';
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.GREYCOLOR};
`
export const OrderImageWrapper = styled.div`
  max-width: 140px;
  min-height: 100px;
  width: 100%;
  overflow-y: auto;

  img {
    max-width: 300px;
    min-height: 200px;
    width: 100%;
    margin: 10px 0;
  }
`

export const TableDataWrapper = styled.div`
  font-family: 'Inter';
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.PRIMARYBLACKCOLOR};
`

export const TaskStatusWrapper = styled.div<IProps>`
  width: 145px;
  padding: 4px 10px;
  border-radius: 40px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;

  color: ${(props) =>
    props.status === 'Order-delivered'
      ? theme.PRIMARYGREENCOLOR
      : props.status === 'Cancelled'
        ? theme.ERROR
        : props.activeIndex === 'Pending'
          ? theme.NOTIFICATIONTEXTCOLOR
          : theme.PRIMARYCOLOR};
  background: ${(props) =>
    props.status === 'Order-delivered'
      ? theme.STATUSSUCCESSBACKGROUNDCOLOR
      : props.status === 'Cancelled'
        ? theme.STATUSERRORBACKGROUNDCOLOR
        : props.activeIndex === 'Pending'
          ? theme.NOTIFICATIONYELLOWCOLOR
          : theme.STATUSBLUECOLOR};
`
export const TaskStatusText = styled.div<IProps>`
  font-family: 'Inter';
  color: ${(props) =>
    props.status === 'Order-delivered'
      ? theme.PRIMARYGREENCOLOR
      : props.status === 'Cancelled'
        ? theme.ERROR
        : props.activeIndex === 'Pending'
          ? theme.NOTIFICATIONTEXTCOLOR
          : theme.PRIMARYCOLOR};
`
export const TaskStatusImage = styled.div<IProps>`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: ${(props) =>
    props.status === 'Order-delivered'
      ? theme.PRIMARYGREENCOLOR
      : props.status === 'Cancelled'
        ? theme.ERROR
        : props.activeIndex === 'Pending'
          ? theme.STATUS_NOTIFICATIONCOLOR
          : theme.PRIMARYCOLOR};
`

export const AdminButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  button {
    flex-direction: row-reverse;
    width: 125px;
    height: 40px;
    gap: 4px;
    font-weight: 600;
    font-size: 14px;
    background-color: ${theme.SECONDARYBLUECOLOR};
    color: ${theme.WHITE};

    @media (max-width: 768px) {
      width: 105px;
      height: 40px;
    }
    svg {
      path {
        fill: ${theme.WHITE};
      }
    }
    :hover {
      background-color: transparent;
      color: ${theme.PRIMARYCOLOR};
      svg {
        path {
          fill: ${theme.PRIMARYCOLOR};
        }
      }
    }
  }
  .active-button {
    svg {
      path {
        fill: ${theme.WHITE};
      }
    }
  }
  .color {
    background-color: ${theme.SECONDARYBLUECOLOR};
  }
`

export const StyledTableWrapper = styled.div`
  overflow-x: scroll;
  border: 1px solid ${theme.GREYBORDER};
  width: 40%;
  margin: 20px;
  &.tracking-table {
    width: unset;
    .ant-table-wrapper .ant-table-tbody > tr > td {
      border-bottom: 1px solid  #f0f0f0 !important; /* Add !important to increase specificity */
    }
  }
 
  @media (max-width: 768px) {
    margin: 0;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    height: 30vh;
    width: 100%;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom: none;
  }

  .ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0px;
  }
  .ant-table-pagination {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .ant-pagination {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
  }

  .ant-pagination-item-active {
    background: ${theme.PAGINATIONBACKGRONDCOLOR};
    border: none;
  }

  .ant-table-thead > tr > th {
    background: transparent !important;
    font-family: Gilroy-Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.006em;
    text-align: left;
    color: rgba(110, 124, 135, 1) !important;
  }
  .ant-table-tbody > tr > td {
    color: ${theme.PRIMARYBLACKCOLOR} !important;
    font-weight: 400 !important;
    div {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.006em;
      text-align: left;
    }
  }
  .ant-table-cell {
    padding: 14px !important;
  }
  .ant-pagination-options {
    display: none;
  }
  .ant-table-wrapper .ant-table-thead > tr > td {
    background: transparent !important;
  }

  .prev-button {
    position: absolute;
    left: 25px;
    color: ${theme.PRIMARYBLACKCOLOR};
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    user-select: none;
    background: white;
    padding: 8px 14px;
    border: 1px solid ${theme.PAGINATION_BUTTON_BORDER};
    border-radius: 10px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .next-button {
    position: absolute;
    right: 25px;
    color: ${theme.PRIMARYBLACKCOLOR};
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    user-select: none;
    padding: 8px 14px;
    background: white;
    border: 1px solid ${theme.PAGINATION_BUTTON_BORDER};
    border-radius: 10px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .ant-table-body {
    overflow-x: auto;
  }

  .ant-table-body::-webkit-scrollbar {
    height: 0px;
  }
  .ant-table-wrapper tr.ant-table-expanded-row > td {
    background: white;
  }
  .ant-table-wrapper tr.ant-table-expanded-row:hover > td {
    background: rgba(250, 250, 250, 1);
  }
  .ant-table-body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .ant-table-body::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
`
