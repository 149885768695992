import logo from 'assets/images/proteanLogo.svg'
import { TopBar } from 'styles/views/driverFlowHome'
import { Logo, LogoSection, LogoText } from 'styles/components/Navbar'
import proteanText from 'assets/images/proteanText.svg'


const MobileTopbar = () => (
  <TopBar>
   <LogoSection>
          <img src={logo} alt="protean" />
          <Logo>
            <img src={proteanText} alt="protean" width={97} height={22} />
            <LogoText>Logistics App</LogoText>
          </Logo>
        </LogoSection>
  </TopBar>
)

export default MobileTopbar
