import { SVGProps } from 'react'

const HomeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5761 2.57564C11.8104 2.34132 12.1903 2.34132 12.4247 2.57564L21.4247 11.5756C21.659 11.81 21.659 12.1899 21.4247 12.4242C21.1903 12.6585 20.8104 12.6585 20.5761 12.4242L19.6004 11.4484V19.9999C19.6004 20.8836 18.884 21.5999 18.0004 21.5999H6.00039C5.11673 21.5999 4.40039 20.8836 4.40039 19.9999V11.4484L3.42465 12.4242C3.19034 12.6585 2.81044 12.6585 2.57613 12.4242C2.34181 12.1899 2.34181 11.81 2.57613 11.5756L11.5761 2.57564ZM5.60039 10.2484V19.9999C5.60039 20.2208 5.77948 20.3999 6.00039 20.3999H9.00039C9.2213 20.3999 9.40039 20.2208 9.40039 19.9999V15.9999C9.40039 15.1162 10.1167 14.3999 11.0004 14.3999H13.0004C13.884 14.3999 14.6004 15.1162 14.6004 15.9999V19.9999C14.6004 20.2208 14.7795 20.3999 15.0004 20.3999H18.0004C18.2213 20.3999 18.4004 20.2208 18.4004 19.9999V10.2484L12.0004 3.84843L5.60039 10.2484ZM13.4508 20.3999C13.4179 20.2721 13.4004 20.138 13.4004 19.9999V15.9999C13.4004 15.779 13.2213 15.5999 13.0004 15.5999H11.0004C10.7795 15.5999 10.6004 15.779 10.6004 15.9999V19.9999C10.6004 20.138 10.5829 20.2721 10.55 20.3999H13.4508Z"
      fill="#181818"
      fillOpacity="0.4"
    />
  </svg>
)
export default HomeIcon
